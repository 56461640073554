import React, { useState, useEffect } from "react";
import axios from "axios";
import DWRForm from "./DWRForm";
import "../styles/DWR.css";
import { newExportObjectAsPDF, exportMultipleDWRsAsCombinedPDF } from "../util/NewExportPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useAuth } from "../api/AuthContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Checkbox, TableRow, TableCell, Button, Tabs, Tab, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';

const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
  throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const DWR = ({ wellId, wellStatus, subprojectId, subprojectType, onClose }) => {
  const { authState } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [dwrs, setDwrs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFullDwr, setIsLoadingFullDwr] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDwr, setSelectedDwr] = useState('');
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [selectedForPrinting, setSelectedForPrinting] = useState([]);
  const [isPrintSelectionMode, setIsPrintSelectionMode] = useState(false);
  const token = authState?.token;
  const currentUser = authState?.user;
  const [sortedDwrs, setSortedDwrs] = useState([]);
  const isWellClosed = wellStatus === 'Closed';
  const [subprojectName, setSubprojectName] = useState('');
  const [subprojectNsId, setSubprojectNsId] = useState(null);
  const [subprojectStatus, setSubprojectStatus] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [filteredDwrs, setFilteredDwrs] = useState([]);



  const fetchSubprojectDetails = () => {
    if (!subprojectId) return;
    
    axios.get(`${appUrl}/jmrapp/subprojects/${subprojectId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setSubprojectName(response.data.name);
        setSubprojectNsId(response.data.netsuite_id); // Save the NetSuite ID
        setSubprojectStatus(response.data.status);
      })
      .catch((error) => {
        console.error("Error fetching subproject details:", error);
      });
  };

  useEffect(() => {
    if (subprojectId) {
      fetchSubprojectDetails();
    }
  }, [subprojectId]);

  useEffect(() => {
    if (wellId && subprojectId) {
      fetchDWRsForWell();
    }
  }, [wellId, subprojectId]);

  const fetchDWRsForWell = async () => {
    setIsLoading(true);
    try {
      let url = `${appUrl}/jmrapp/dailyworkrecords/?well=${wellId}&detail=true`;  // Add detail=true
      if (subprojectId) {
        url += `&subproject=${subprojectId}`;
      }
  
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const sortedData = response.data.sort((a, b) => 
        new Date(a.date) - new Date(b.date)
      );
      setDwrs(sortedData);
      setSortedDwrs(sortedData);
    } catch (error) {
      console.error("Error fetching DWRs:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };


  const getAdjacentDWR = (currentDwr, direction) => {
    if (!currentDwr) return null;

    const currentIndex = sortedDwrs.findIndex(dwr => dwr.id === currentDwr.id);
    if (currentIndex === -1) return null;

    if (direction === 'next' && currentIndex < sortedDwrs.length - 1) {
      return sortedDwrs[currentIndex + 1];
    } else if (direction === 'prev' && currentIndex > 0) {
      return sortedDwrs[currentIndex - 1];
    }
    return null;
  };

  const fetchFullDWRData = async (dwrId) => {
    try {
      const response = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/${dwrId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch full DWR data for ID ${dwrId}:`, error);
      throw error;
    }
  };
  const handlePDFDownload = async (dwr, event) => {
    event.stopPropagation();
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      await newExportObjectAsPDF(fullDwrData, token);
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to generate PDF. Please try again.",
        buttons: [{ label: "OK" }],
      });
    }
  };

  const handleDeleteToggle = () => {
    setIsDeleteMode(!isDeleteMode);
    setSelectedForDeletion([]);
  };

  const handleCancelDelete = () => {
    setIsDeleteMode(false);
    setSelectedForDeletion([]);
  };

  const handleCheckboxChange = (dwrId) => {
    setSelectedForDeletion((prevSelected) =>
      prevSelected.includes(dwrId)
        ? prevSelected.filter((id) => id !== dwrId)
        : [...prevSelected, dwrId]
    );
  };

  const handleDeleteSelectedDWRs = async () => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete the selected DWRs?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await Promise.all(
                selectedForDeletion.map((dwrId) =>
                  axios.delete(`${appUrl}/jmrapp/dailyworkrecords/${dwrId}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                  })
                )
              );
              fetchDWRsForWell();
              setIsDeleteMode(false);
              setSelectedForDeletion([]);
            } catch (error) {
              console.error('Failed to delete selected DWRs:', error);
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const handlePrintSelectionToggle = () => {
    setIsPrintSelectionMode(!isPrintSelectionMode);
    setSelectedForPrinting([]);
  };

  const handlePrintCheckboxChange = (dwrId) => {
    setSelectedForPrinting((prevSelected) =>
      prevSelected.includes(dwrId)
        ? prevSelected.filter((id) => id !== dwrId)
        : [...prevSelected, dwrId]
    );
  };

  const handlePrintSelectedDWRs = async () => {
    setIsDownloading(true);
    try {
      const selectedDwrs = dwrs.filter(dwr => selectedForPrinting.includes(dwr.id));
      await exportMultipleDWRsAsCombinedPDF(selectedDwrs, token);
      setIsPrintSelectionMode(false);
      setSelectedForPrinting([]);
    } catch (error) {
      console.error('Failed to print selected DWRs:', error);
    } finally {
      setIsDownloading(false);
    }
  };


  const StyledTableRow = styled(TableRow)(({ theme, islastday, isbreakpoint }) => ({
    backgroundColor: islastday === 'true' ? 'rgba(255, 253, 231, 1)' : 'inherit',
    '&:hover': {
      backgroundColor: islastday === 'true' ? 'rgba(255, 253, 231, 0.7)' : theme.palette.action.hover,
    },
    borderBottom: isbreakpoint === 'true' ? '2px solid #FFD700' : 'inherit',
    position: 'relative',
  }));

  const BreakpointTotal = styled('div')({
    position: 'absolute',
    right: '8px',
    bottom: '-10px',
    backgroundColor: '#FFD700',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.875rem',
    fontWeight: 'bold',
  });

  const calculateBreakpoints = (dwrs) => {
    const THRESHOLD = 90000;
    let runningTotal = 0;
    let segmentTotal = 0;
    const breakpoints = new Set();
    const totals = new Map();

    // First pass: calculate running totals and identify breakpoints
    dwrs.forEach((dwr, index) => {
      const amount = parseFloat(dwr.total_amount) || 0;
      runningTotal += amount;
      segmentTotal += amount;
      
      // Check if we've crossed a threshold
      if (Math.floor(runningTotal / THRESHOLD) > Math.floor((runningTotal - amount) / THRESHOLD)) {
        breakpoints.add(index);
        totals.set(index, segmentTotal);
        segmentTotal = 0;
      }
    });

    return { breakpoints, totals };
  };
  
  const handleDwrClick = async (dwr) => {
    setIsLoadingFullDwr(true);
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      setSelectedDwr(fullDwrData);
      
      if (isWellClosed) {
        confirmAlert({
          title: "Well Closed",
          message: "This well is closed. You can view DWRs for closed wells, but you cannot edit them.",
          buttons: [
            {
              label: "View DWR",
              onClick: () => setShowForm(true)
            },
            {
              label: "Cancel",
              onClick: () => setSelectedDwr(null)
            }
          ]
        });
      } else {
        setShowForm(true);
      }
    } catch (error) {
      console.error('Failed to fetch full DWR data:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to load DWR data. Please try again.",
        buttons: [{ label: "OK" }],
      });
    } finally {
      setIsLoadingFullDwr(false);
    }
  };

  const handleCreateNewDwr = () => {
    if (isWellClosed) {
      confirmAlert({
        title: "Well Closed",
        message: "This well is closed. You cannot create new DWRs for closed wells.",
        buttons: [{ label: "OK" }],
      });
    } else if (!subprojectId) {
      confirmAlert({
        title: "No Subproject Selected",
        message: "Please select a subproject before creating a new DWR.",
        buttons: [{ label: "OK" }],
      });
    } else {
      setSelectedDwr(null);
      setShowForm(true);
    }
  };

  const handleToggleForm = () => {
    setSelectedDwr(null);
    setShowForm(false);
    fetchDWRsForWell(); // Fetch DWRs again when the form is closed
    if (onClose) {
      onClose();  // Add this line
    }

  };

  const handleApprovalClick = async (dwr) => {
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      const pdfBlob = await newExportObjectAsPDF(fullDwrData, token, false);
      const pdfFile = new Blob([pdfBlob], { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('pdf', pdfFile, 'dwr.pdf');

      if (currentUser && (currentUser.is_district_manager || currentUser.is_superuser)) {
        await axios.post(appUrl + `/jmrapp/dailyworkrecords/${dwr.id}/send_customer_approval_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Approval request sent to customer successfully');
      } else {
        await axios.post(appUrl + `/jmrapp/dailyworkrecords/${dwr.id}/send_reports_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Approval request sent to manager successfully');
      }
    } catch (error) {
      console.error('Failed to send approval request:', error);
    }
  };
  const handleCombineAndDownloadPDFs = async () => {
    setIsDownloading(true);
    try {
      const sortedDwrs = [...dwrs].sort((a, b) => new Date(a.date) - new Date(b.date));
      const fullDwrsData = await Promise.all(sortedDwrs.map(dwr => fetchFullDWRData(dwr.id)));
      await exportMultipleDWRsAsCombinedPDF(fullDwrsData, token);
    } catch (error) {
      console.error('Failed to combine and download PDFs:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!dwrs.length) return;
    
    switch (tabValue) {
      case 0: // Approved
        setFilteredDwrs(dwrs.filter(dwr => dwr.is_approved));
        break;
      case 1: // Unapproved
        setFilteredDwrs(dwrs.filter(dwr => !dwr.is_approved));
        break;
      case 2: // All
        setFilteredDwrs(dwrs);
        break;
      default:
        setFilteredDwrs(dwrs);
    }
  }, [tabValue, dwrs]);

  return (
    <>
      {subprojectName && (
        <div className="bg-gray-50 p-4 mb-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-gray-700">
            Showing DWRs for {subprojectName}
            {subprojectStatus === 'Closed' && (
              <span className="ml-2 text-sm font-normal text-gray-500">(Closed)</span>
            )}
          </h2>
        </div>
      )}

      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '14px',
              minWidth: '120px',
              padding: '8px 16px',
            },
            '& .Mui-selected': {
              color: '#0b6e4f !important',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#0b6e4f',
            }
          }}
        >
          <Tab label="Approved" />
          <Tab label="Unapproved" />
          <Tab label="All" />
        </Tabs>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem' }}>
          <Button
            variant="contained"
            onClick={subprojectStatus === 'Closed' ? () => {} : handleCreateNewDwr}
            disabled={isWellClosed || subprojectStatus === 'Closed'}
            sx={{
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: subprojectStatus === 'Closed' ? '#0b6e4f' : '#11372b',
              },
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '14px',
            }}
          >
            {subprojectStatus === 'Closed' ? 'View Closed Project' : 'Create New Form'}
          </Button>
          <Button
            variant="contained"
            onClick={handleCombineAndDownloadPDFs}
            disabled={isDownloading}
            sx={{
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#11372b',
              },
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '14px',
            }}
          >
            {isDownloading ? 'Downloading...' : 'Download All PDFs'}
          </Button>
                    {isPrintSelectionMode ? (
            <>
              <Button
                variant="contained"
                onClick={handlePrintSelectedDWRs}
                disabled={selectedForPrinting.length === 0 || isDownloading}
                sx={{
                  backgroundColor: '#0b6e4f',
                  '&:hover': {
                    backgroundColor: '#11372b',
                  },
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '14px',
                }}
              >
                {isDownloading ? 'Downloading...' : 'Print Selected DWRs'}
              </Button>
              <Button
                variant="outlined"
                onClick={handlePrintSelectionToggle}
                sx={{
                  color: '#0b6e4f',
                  borderColor: '#0b6e4f',
                  '&:hover': {
                    backgroundColor: '#11372b',
                    color: '#ffffff',
                    borderColor: '#11372b',
                  },
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '14px',
                }}
              >
                Cancel Selection
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={handlePrintSelectionToggle}
              sx={{
                backgroundColor: '#0b6e4f',
                '&:hover': {
                  backgroundColor: '#11372b',
                },
                textTransform: 'none',
                padding: '8px 16px',
                fontSize: '14px',
              }}
            >
              Select DWRs to Print
            </Button>
          )}
          {isDeleteMode ? (
            <>
              <Button
                variant="contained"
                onClick={handleDeleteSelectedDWRs}
                sx={{
                  backgroundColor: '#0b6e4f',
                  '&:hover': {
                    backgroundColor: '#11372b',
                  },
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '14px',
                }}
              >
                Delete Selected DWRs
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelDelete}
                sx={{
                  color: '#0b6e4f',
                  borderColor: '#0b6e4f',
                  '&:hover': {
                    backgroundColor: '#11372b',
                    color: '#ffffff',
                    borderColor: '#11372b',
                  },
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '14px',
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={handleDeleteToggle}
              sx={{
                backgroundColor: '#0b6e4f',
                '&:hover': {
                  backgroundColor: '#11372b',
                },
                textTransform: 'none',
                padding: '8px 16px',
                fontSize: '14px',
              }}
            >
              Select DWRs for Deletion
            </Button>
          )}
        </div>
      </Box>

      <Divider sx={{ my: 2 }} />

      {showForm && (
        <div>
          <DWRForm
            selectedDwr={selectedDwr}
            setSelectedDwr={setSelectedDwr}
            onClose={handleToggleForm}
            setShowForm={setShowForm}
            onSubmit={() => setShowForm(false)}
            getAdjacentDWR={getAdjacentDWR}
            fetchFullDWRData={fetchFullDWRData}
            subprojectId={subprojectId}
            subprojectType={subprojectType}
            subprojectNsId={subprojectNsId}
          />
          <Button
            variant="contained"
            onClick={handleToggleForm}
            sx={{
              marginTop: '10px',
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#11372b',
              },
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '14px',
            }}
          >
            Close Form
          </Button>
        </div>
      )}

      {!showForm && (
        <div style={{ position: 'relative' }}>
          {isLoading ? (
            <p>Loading DWRs...</p>
          ) : error ? (
            <p>Error fetching DWRs: {error.message}</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Notes</th>
                  <th>Approved</th>
                  <th>SO Creation Date</th>
                  <th>Superintendent</th>
                  <th>Supervisor</th>
                  <th>Last Day</th>
                  <th>
                    {currentUser && (currentUser.is_district_manager || currentUser.is_superuser)
                      ? 'Customer Approval'
                      : 'Manager Approval'}
                  </th>   
                  <th>Total Amount</th>               
                  <th>PDF</th>
                  {(isDeleteMode || isPrintSelectionMode) && <th>{isDeleteMode ? 'Delete' : 'Print'}</th>}                </tr>
              </thead>
              <tbody>
                {(() => {
                  const { breakpoints, totals } = calculateBreakpoints(filteredDwrs);
                  
                  return filteredDwrs.map((dwr, index) => (
                    <StyledTableRow 
                      key={dwr.id} 
                      islastday={dwr.is_last_day.toString()}
                      isbreakpoint={breakpoints.has(index).toString()}
                    >
                      <TableCell
                        onClick={() => handleDwrClick(dwr)}
                        className={`clickable-link ${isLoadingFullDwr ? 'loading' : ''}`}
                      >
                        {new Date(dwr.date).toLocaleDateString("en-US", { timeZone: "UTC" })} - {dwr.day}
                      </TableCell>
                      <TableCell>{dwr.notes}</TableCell>
                      <TableCell>
                        <Checkbox checked={!!dwr.is_approved} disabled />
                      </TableCell>
                      <TableCell>
                        {dwr.so_created_at 
                          ? new Date(dwr.so_created_at).toLocaleString("en-US", {
                              timeZone: "UTC",
                              dateStyle: "short",
                              timeStyle: "short"
                            })
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {dwr.primary_manager 
                          ? `${dwr.primary_manager.first_name} ${dwr.primary_manager.last_name}`
                          : 'N/A'}
                      </TableCell>
                      <TableCell>                      
                        {dwr.created_by
                          ? `${dwr.created_by.first_name} ${dwr.created_by.last_name}`
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <Checkbox checked={!!dwr.is_last_day} disabled />
                      </TableCell>
                      <TableCell>
                        {dwr.status === 'In Progress' && (
                          <button onClick={() => handleApprovalClick(dwr)}>
                            {currentUser && (currentUser.is_district_manager || currentUser.is_superuser)
                              ? 'Send to Customer'
                              : 'Send to Reports'}
                          </button>
                        )}
                      </TableCell>
                      <TableCell>
                        ${(() => {
                          // First try to get the pre-calculated total
                          if (dwr.total_amount !== undefined && dwr.total_amount !== null) {
                            return Number(dwr.total_amount).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            });
                          }
                          
                          // If no pre-calculated total, calculate from non-turnkey charge records only
                          if (dwr.charge_records && dwr.charge_records.length > 0) {
                            const total = dwr.charge_records
                              .filter(record => !record.on_turnkey)  // Only include non-turnkey records
                              .reduce((sum, record) => {
                                // Ensure we're using the numeric value of total_amount
                                const recordTotal = Number(record.total_amount) || 0;
                                return sum + recordTotal;
                              }, 0);
                            
                            return total.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            });
                          }
                          
                          return '0.00';
                        })()}
                      </TableCell>
                      <TableCell>
                        <button onClick={(event) => handlePDFDownload(dwr, event)}>
                          <FileDownloadIcon />
                        </button>
                      </TableCell>
                      {(isDeleteMode || isPrintSelectionMode) && (
                        <TableCell>
                          <Checkbox
                            checked={isDeleteMode 
                              ? selectedForDeletion.includes(dwr.id)
                              : selectedForPrinting.includes(dwr.id)
                            }
                            onChange={() => isDeleteMode 
                              ? handleCheckboxChange(dwr.id)
                              : handlePrintCheckboxChange(dwr.id)
                            }
                          />
                        </TableCell>
                      )}
                      {breakpoints.has(index) && (
                        <BreakpointTotal>
                          Accumulated Total: ${totals.get(index).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </BreakpointTotal>
                      )}
                    </StyledTableRow>
                  ));
                })()}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default DWR;
