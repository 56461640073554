import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/WellRows.css'; // Reuse the same styles

const SubprojectRows = ({ data, onSelectWell }) => {
  console.log("SUBPROJECTS: ", data);

  const [filterModel, setFilterModel] = useState(() => {
    const savedFilter = localStorage.getItem('subprojectRowsFilter');
    return savedFilter ? JSON.parse(savedFilter) : { items: [] };
  });

  useEffect(() => {
    localStorage.setItem('subprojectRowsFilter', JSON.stringify(filterModel));
  }, [filterModel]);

  const formatArea = (areaValue) => {
    return areaValue === 'Deleware Basin' ? 'Delaware Basin' : areaValue;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'project_name',
      headerName: 'WELL',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'subproject_type', headerName: 'TYPE', flex: 1 },
    { field: 'area', headerName: 'AREA', flex: 1, valueGetter: (params) => formatArea(params.value) },
    { field: 'assigned_rig', headerName: 'RIG', flex: 1, valueGetter: (params) => params.row.assigned_rig || '' },
    { field: 'customer_name', headerName: 'CUSTOMER', flex: 1 },
    { field: 'status', headerName: 'STATUS', flex: 1 },
    { field: 'start_date', headerName: 'START DATE', flex: 1 },
    { field: 'end_date', headerName: 'END DATE', flex: 1 },
  ];

  const rows = data.map((subproject, index) => {
    return {
      id: subproject.id || index,
      name: subproject.name,
      project_name: subproject.project_name,
      project_id: subproject.project_id,
      subproject_type: subproject.subproject_type || '',
      area: subproject.area || '',
      assigned_rig: subproject.assigned_rig || 'No Rig',
      assigned_rig_id: subproject.assigned_rig_id,
      customer_name: subproject.customer_name || 'No Customer',
      status: subproject.status || '',
      start_date: subproject.start_date || '',
      end_date: subproject.end_date || '',
    };
  });

  // Sort by start_date (most recent first)
  rows.sort((a, b) => {
    if (a.start_date && b.start_date) {
      return new Date(b.start_date) - new Date(a.start_date);
    } else if (a.start_date) {
      return -1;
    } else if (b.start_date) {
      return 1;
    } else {
      return 0;
    }
  });

  // Handle row click to navigate to the associated well
  const handleRowClick = (params) => {
    // Create a well object with the project_id as the well id
    const wellData = {
      id: params.row.project_id,
      name: params.row.project_name,
      area: params.row.area,
      assigned_rig: params.row.assigned_rig,
      assigned_rig_id: params.row.assigned_rig_id,
      customer_name: params.row.customer_name,
      status: params.row.status,
    };
    
    // Call onSelectWell with the well data
    onSelectWell(wellData);
  };

  return (
    <div style={{ height: '75vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default SubprojectRows; 