import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  FormControl,
  InputLabel
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from 'axios';
import { newExportObjectAsPDF } from "../util/NewExportPdf";
import { exportRevenueReportAsPDF } from "../util/RevenueReportPdf";
import { exportReclamationRevenueReportAsPDF } from "../util/ReclamationRevenueReportPdf";
import { exportRigPointsReportAsPDF } from "../util/RigPointsReportPdf";

const appUrl = process.env.REACT_APP_APP_URL;

const QueryView = () => {
  const [queries, setQueries] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [parameters, setParameters] = useState({});
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rigs, setRigs] = useState([]);
  const [downloadingPdf, setDownloadingPdf] = useState(false);

  useEffect(() => {
    fetchQueries();
    fetchRigs();
  }, []);

  const fetchQueries = async () => {
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/saved-queries/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setQueries(response.data);
    } catch (err) {
      setError('Failed to fetch queries');
    }
  };

  const fetchRigs = async () => {
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/rigs/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRigs(response.data);
    } catch (err) {
      setError('Failed to fetch rigs');
    }
  };

  const getDefaultDates = () => {
    const today = new Date();
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    
    return {
      start_date: firstDayLastMonth.toISOString().split('T')[0],
      end_date: lastDayLastMonth.toISOString().split('T')[0]
    };
  };

  const handleQuerySelection = (e) => {
    const query = queries.find(q => q.id === e.target.value);
    setSelectedQuery(query);
    
    // Reset parameters
    if (query) {
      const defaultDates = getDefaultDates();
      const newParams = {};
      
      // Only keep parameters that are defined in the selected query
      Object.keys(query.parameters || {}).forEach(key => {
        if (key === 'start_date') {
          newParams[key] = defaultDates.start_date;
        } else if (key === 'end_date') {
          newParams[key] = defaultDates.end_date;
        } else {
          newParams[key] = parameters[key] || '';
        }
      });
      
      setParameters(newParams);
    } else {
      setParameters({});
    }
    setResults(null);
  };

  const executeQuery = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('my-jwt');
      const execParams = {};
      
      // Only include parameters that are defined in the selected query
      if (selectedQuery?.parameters) {
        Object.keys(selectedQuery.parameters).forEach(key => {
          let value = parameters[key];
          
          // Handle default values for dates
          if ((key === 'start_date' || key === 'end_date') && !value) {
            const defaultDates = getDefaultDates();
            value = defaultDates[key];
          }
          
          // Handle null values
          if (value === '' || value === 'null' || value === undefined) {
            value = null;
          }
          
          // Convert string numbers to integers for integer parameters
          if (selectedQuery.parameters[key].type === 'integer' && value !== null) {
            value = parseInt(value);
          }
          
          // Always include parameters in execParams, even if null
          execParams[key] = value;
        });
      }

      const response = await axios.post(
        `${appUrl}/jmrapp/saved-queries/${selectedQuery.id}/execute/`,
        { parameters: execParams },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setResults(response.data.results);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to execute query');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = async () => {
    if (!selectedQuery) return;
    const token = localStorage.getItem('my-jwt');
    const execParams = {};
    
    // Only include parameters that are defined in the selected query
    if (selectedQuery?.parameters) {
      Object.keys(selectedQuery.parameters).forEach(key => {
        let value = parameters[key];
        
        // Handle default values for dates
        if ((key === 'start_date' || key === 'end_date') && !value) {
          const defaultDates = getDefaultDates();
          value = defaultDates[key];
        }
        
        // Handle null values
        if (value === '' || value === 'null' || value === undefined) {
          value = null;
        }
        
        // Convert string numbers to integers for integer parameters
        if (selectedQuery.parameters[key].type === 'integer' && value !== null) {
          value = parseInt(value);
        }
        
        execParams[key] = value;
      });
    }

    // Convert parameters to URL-safe format
    const params = new URLSearchParams();
    Object.entries(execParams).forEach(([key, value]) => {
      // Skip null values for integer parameters
      if (selectedQuery.parameters[key].type === 'integer' && value === null) {
        return;
      }
      
      // For required parameters or non-null values
      if (selectedQuery.parameters[key].required || value !== null) {
        if (value === null) {
          // For required non-integer parameters that are null, send empty string
          params.append(key, '');
        } else if (typeof value === 'object') {
          // Handle objects/arrays if needed
          params.append(key, JSON.stringify(value));
        } else {
          // Handle primitive values
          params.append(key, value.toString());
        }
      }
    });
    
    try {
      const response = await axios({
        url: `${appUrl}/jmrapp/saved-queries/${selectedQuery.id}/download_csv/?${params.toString()}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedQuery.name}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to download CSV');
    }
  };

  const handlePDFDownload = async () => {
    if (!selectedQuery || !results || !results.length) return;
    
    setDownloadingPdf(true);
    try {
      const token = localStorage.getItem('my-jwt');
      
      // Base PDF data structure
      const pdfData = {
        report_type: selectedQuery.name.toLowerCase().replace(/\s+/g, '_'),
        report_name: selectedQuery.name,
        date_range: {
          from_date: parameters.start_date,
          to_date: parameters.end_date
        },
        rig_filter: parameters.rig_id ? rigs.find(r => r.id === parameters.rig_id)?.name : 'All Rigs',
        results: results
      };

      // Add report-specific summary data and call appropriate export function
      if (selectedQuery.name === "P&A Revenue Report" || selectedQuery.name === "Reclamation Revenue Report") {
        pdfData.results = results.map(row => ({
          ...row,
          well: {
            name: row['Well Name'],
            api: row['API Number'],
            customer: {
              name: row['Customer']
            }
          }
        }));
        pdfData.summary = {
          total_revenue: results.reduce((sum, row) => sum + Number(row['Total Revenue'] || 0), 0).toFixed(2),
          avg_revenue_per_day: (results.reduce((sum, row) => sum + Number(row['Revenue per Day'] || 0), 0) / results.length).toFixed(2)
        };
        if (selectedQuery.name === "P&A Revenue Report") {
          await exportRevenueReportAsPDF(pdfData, token);
        } else {
          await exportReclamationRevenueReportAsPDF(pdfData, token);
        }
      } else if (selectedQuery.name === "Rig Points Report") {
        pdfData.summary = {
          total_points: results.reduce((sum, row) => sum + Number(row.total_points || 0), 0).toFixed(1),
          total_work_days: results.reduce((sum, row) => sum + Number(row.total_work_days || 0), 0),
          avg_hours_overall: (results.reduce((sum, row) => sum + (Number(row.mode_crew_hours || 0) * Number(row.total_work_days || 0)), 0) / 
                            results.reduce((sum, row) => sum + Number(row.total_work_days || 0), 0)).toFixed(2)
        };
        await exportRigPointsReportAsPDF(pdfData, token);
      } else {
        await newExportObjectAsPDF(pdfData, token);
      }
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      setError('Failed to generate PDF report');
    } finally {
      setDownloadingPdf(false);
    }
  };

  const renderParameterInput = (key, info) => {
    if (key === 'rig_id') {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel id="rig-select-label">Rig</InputLabel>
          <Select
            labelId="rig-select-label"
            value={parameters[key] || ''}
            onChange={(e) => setParameters({
              ...parameters,
              [key]: e.target.value || null
            })}
            label="Rig"
          >
            <MenuItem value="">All Rigs</MenuItem>
            {rigs.sort((a, b) => a.name.localeCompare(b.name)).map(rig => (
              <MenuItem key={rig.id} value={rig.id}>
                {rig.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (info.type === 'date') {
      return (
        <TextField
          key={key}
          label={info.label || key}
          type="date"
          value={parameters[key] || ''}
          onChange={(e) => setParameters({
            ...parameters,
            [key]: e.target.value
          })}
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      );
    }

    return (
      <TextField
        key={key}
        label={info.label || key}
        value={parameters[key] || ''}
        onChange={(e) => setParameters({
          ...parameters,
          [key]: e.target.value
        })}
        placeholder={info.placeholder}
        fullWidth
        margin="normal"
        variant="outlined"
      />
    );
  };

  const getCellStyle = (header, value, row) => {
    // Apply color coding for Revenue per Day column based on revenue_color field
    if (header === 'Revenue per Day' && row.revenue_color) {
      return {
        backgroundColor: row.revenue_color === 'green' ? '#e6ffe6' :
                        row.revenue_color === 'yellow' ? '#fffff0' :
                        '#ffe6e6',
        fontWeight: 'bold'
      };
    }
    
    // Format currency values
    if (header === 'Total Revenue' || header === 'Revenue per Day') {
      return {
        textAlign: 'right'
      };
    }
    
    return {};
  };

  const formatCellValue = (header, value) => {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (header.toLowerCase().includes('date') && value) {
      try {
        return new Date(value).toLocaleDateString();
      } catch (e) {
        console.error('Error formatting date:', e);
        return value;
      }
    }
    return value?.toString();
  };

  return (
    <Card sx={{ m: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          SQL Query Interface
        </Typography>

        <Select
          fullWidth
          value={selectedQuery?.id || ''}
          onChange={handleQuerySelection}
          sx={{ mb: 2 }}
        >
          <MenuItem value="">Select a query</MenuItem>
          {queries.map(query => (
            <MenuItem key={query.id} value={query.id}>
              {query.name}
            </MenuItem>
          ))}
        </Select>

        {selectedQuery?.parameters && Object.keys(selectedQuery.parameters).length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Parameters
            </Typography>
            {Object.entries(selectedQuery.parameters).map(([key, info]) => 
              renderParameterInput(key, info)
            )}
          </Box>
        )}

        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={executeQuery}
            disabled={!selectedQuery || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Execute Query'}
          </Button>
          {results && (
            <>
              <Button
                variant="outlined"
                onClick={downloadCSV}
                startIcon={<DownloadIcon />}
              >
                Download CSV
              </Button>
              <Button
                variant="outlined"
                onClick={handlePDFDownload}
                disabled={downloadingPdf}
                startIcon={<PictureAsPdfIcon />}
              >
                {downloadingPdf ? 'Generating PDF...' : 'Download PDF'}
              </Button>
            </>
          )}
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {results && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(results[0] || {})
                    .filter(header => header !== 'revenue_color' && 
                                    header !== 'Report Period' && 
                                    header !== 'Last Day' && 
                                    header !== 'Project Start Date')
                    .map(header => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((row, i) => (
                  <TableRow key={i}>
                    {Object.entries(row)
                      .filter(([key]) => key !== 'revenue_color' && 
                                       key !== 'Report Period' && 
                                       key !== 'Last Day' && 
                                       key !== 'Project Start Date')
                      .map(([header, value], j) => (
                      <TableCell 
                        key={j}
                        style={getCellStyle(header, value, row)}
                      >
                        {formatCellValue(header, value)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default QueryView;
