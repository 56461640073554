import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import TimeRecordRow from './TimeRecordRow';
import TimeRecordModal from './TimeRecordModal';
import axios from 'axios';
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../styles/TimeRecord.css';
import { useAuth } from '../api/AuthContext';
import { convertTo12Hour } from './convertTo12Hour';
import { convertTo24Hour } from './convertTo24Hour';
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types';

  // Get App URL
  const appUrl = process.env.REACT_APP_APP_URL;

  if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
  }


const TimeRecord = forwardRef(({ employees, existingETRs = [], selectedDwr, disabled, rigData }, ref) => {
  const { authState } = useAuth();
  const token = authState?.token;
  const [timeRecordState, setTimeRecordState] = useState({
    rows: [],
    selectedRoles: [],
    defaultRoles: [
      { title: 'rig_supervisor', id: 'default-1', data: {} },
      { title: 'rig_operator', id: 'default-2', data: {} },
      { title: 'derrick_hand', id: 'default-3', data: {} },
      { title: 'floor_hand', id: 'default-4', data: {} },
      { title: 'reclamation_supervisor', id: 'default-5', data: {} },
      { title: 'heavy_equipment_operator', id: 'default-6', data: {} },
      { title: 'spotter', id: 'default-7', data: {} },
      { title: 'driver', id: 'default-8', data: {} },
    ],
  });


  // console.log(existingETRs)
  // console.log('SELECTED DWR TR', selectedDwr.time_record.id)

  const [isTRModalOpen, setIsTRModalOpen] = useState(false);

  const handleOpenTRModal = () => setIsTRModalOpen(true);
  const handleCloseTRModal = () => setIsTRModalOpen(false);

  const roleToTitleMap = {
    rig_supervisor: 'Rig Supervisor',
    rig_operator: 'Rig Operator',
    derrick_hand: 'Derrick Hand',
    floor_hand: 'Floor Hand',
    reclamation_supervisor: 'Reclamation Supervisor',
    heavy_equipment_operator: 'Heavy Equipment Operator',
    spotter: 'Spotter',
    driver: 'Driver',
  };

  const existingETRTitles = existingETRs.map((etr) => roleToTitleMap[etr.role]);
  const transformExistingETRs = useCallback((etrs) => {
    // console.log('Raw ETRs before transformation:', etrs);
    const transformed = etrs.map((etr) => {
      // Get the role title based on whether it's Rig 209 or legacy
      const roleTitle = etr.role.includes('_') ? roleToTitleMap[etr.role] : etr.role;
      // console.log('Processing ETR:', {
      //   originalRole: etr.role,
      //   mappedTitle: roleTitle,
      //   employeeId: etr.employee.id
      // });
      
      const result = {
        id: etr.id.toString(),
        title: roleTitle,
        data: {
          etrId: etr.id,
          employeeId: etr.employee.id,
          role: etr.role,
          rig: parseFloat(etr.rig_time),
          travel: parseFloat(etr.travel_time),
          nptJmr: parseFloat(etr.npt_company_time),
          nptCust: parseFloat(etr.npt_customer_time),
          startHour: convertTo12Hour(etr.start_time).hour,
          startMinute: convertTo12Hour(etr.start_time).minute,
          startMeridian: convertTo12Hour(etr.start_time).meridian,
          stopHour: convertTo12Hour(etr.end_time).hour,
          stopMinute: convertTo12Hour(etr.end_time).minute,
          stopMeridian: convertTo12Hour(etr.end_time).meridian,
          timeRecordId: etr.time_record,
        },
      };
      // console.log('Example transformed ETR row structure:', result);
      return result;
    });
    return transformed;
  }, []);

  useEffect(() => {
    if (selectedDwr && selectedDwr.time_record && selectedDwr.time_record.employee_time_records) {
      // console.log('Selected DWR Time Records:', selectedDwr.time_record.employee_time_records);
      const transformedETRs = transformExistingETRs(selectedDwr.time_record.employee_time_records);
      // console.log('Transformed ETRs:', transformedETRs);
      setTimeRecordState((prevState) => ({
        ...prevState,
        rows: transformedETRs,
      }));
    } else {
      setTimeRecordState((prevState) => ({
        ...prevState,
        rows: [],
      }));
    }
  }, [selectedDwr, transformExistingETRs]);




  // const createTimeRecordsFromSelectedRoles = useCallback((selectedRoles) => {
  //   const newRows = selectedRoles.flatMap((role) =>
  //     Array.from({ length: role.count }, () => ({
  //       title: role.title,
  //       id: nanoid(),
  //       data: {
  //         role: role.title,
  //         job_title_display: role.title,
  //         timeRecordId: selectedDwr?.time_record?.id || null,
  //         employeeId: '',
  //         employeeSignature: '',
  //         startHour: '6',
  //         startMinute: '00',
  //         startMeridian: 'AM',
  //         stopHour: '6',
  //         stopMinute: '00',
  //         stopMeridian: 'PM',
  //         rig: 0,
  //         travel: 0,
  //         nptJmr: 0,
  //         nptCust: 0,
  //         ...role.data,
  //       },
  //     }))
  //   );
  //   setTimeRecordState((prevState) => {
  //     const updatedRows = [...prevState.rows, ...newRows];
  //     return {
  //       ...prevState,
  //       rows: updatedRows,
  //       selectedRoles: [],
  //     };
  //   });
  // }, [selectedDwr]);

  const createTimeRecordsFromSelectedRoles = useCallback((selectedRoles) => {
    const newRows = selectedRoles.flatMap((role) =>
      Array.from({ length: role.count }, () => {
        const id = nanoid();
        return {
          title: role.title,
          id,
          data: {
            id: id,
            role: role.title,
            job_title_display: role.title,
            timeRecordId: selectedDwr?.time_record?.id || null,
            employeeId: '',
            employeeSignature: '',
            startHour: '6',
            startMinute: '00',
            startMeridian: 'AM',
            stopHour: '6',
            stopMinute: '00',
            stopMeridian: 'PM',
            rig: 0,
            travel: 0,
            nptJmr: 0,
            nptCust: 0,
            ...role.data,
          },
        };
      })
    );
    setTimeRecordState((prevState) => {
      const updatedRows = [...prevState.rows, ...newRows];
      return {
        ...prevState,
        rows: updatedRows,
        selectedRoles: [],
      };
    });
  }, [selectedDwr]);

  const submitTimeRecords = useCallback(async () => {
    const promises = timeRecordState.rows.map(async (row) => {
      const {
        id,
        etrId,
        employeeId,
        startHour,
        startMinute,
        startMeridian,
        stopHour,
        stopMinute,
        stopMeridian,
        rig,
        travel,
        nptJmr,
        nptCust,
        role,
        timeRecordId,
      } = row.data;

      // console.log('Start Time:', startHour, startMinute, startMeridian);
      // console.log('Stop Time:', stopHour, stopMinute, stopMeridian);
  
      
      const startTime = startHour && startMinute && startMeridian
      ? convertTo24Hour(startHour, startMinute, startMeridian)
      : null;
      const endTime = stopHour && stopMinute && stopMeridian
        ? convertTo24Hour(stopHour, stopMinute, stopMeridian)
        : null;
  
      const payload = {
        employee_id: employeeId,
        start_time: startTime,
        end_time: endTime,
        rig_time: parseFloat(rig),
        travel_time: parseFloat(travel),
        npt_company_time: parseFloat(nptJmr),
        npt_customer_time: parseFloat(nptCust),
        role: row.data.role || row.title,
        time_record_id: timeRecordId,
      };

      let url = appUrl + `/jmrapp/employeetimerecords/`;
      let method = 'POST';
      if (etrId) {
        url += `${etrId}/`;
        method = 'PATCH';
      }

      try {
        // console.log('PAYLOAD FOR ETR Edit ', payload)
        const response = await axios({
          method,
          url,
          data: payload,
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!etrId) {
          row.data.etrId = response.data.id;
        }
        return response.data;
      } catch (error) {
        console.error('Failed to submit ETR:', error.response.data);
      }
    });

    try {
      const results = await Promise.all(promises);
      // console.log('ETRs submitted successfully:', results);
      confirmAlert({
        title: 'Success',
        message: 'Time records updated successfully.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error('An error occurred during ETR submission:', error);
    }
  }, [timeRecordState.rows, token]);

  useImperativeHandle(ref, () => ({
    getRowsData: () => {
      // console.log('timeRecordState.rows before sending to DWRForm:', timeRecordState.rows);
      return timeRecordState.rows;
    },
    submitETRs: submitTimeRecords,
  }));

  const handleRowChange = useCallback((rowId, updatedData) => {
    setTimeRecordState((prevState) => {
      const updatedRows = prevState.rows.map((row) => {
        if (row.id === rowId) {
          const updatedRow = {
            ...row,
            data: {
              ...row.data,
              ...updatedData,
            },
          };
          // console.log('Updated Row TEST:', updatedRow); // Add this line
          return updatedRow;
        }
        return row;
      });
      // console.log('Updated Rows:', updatedRows); // Add this line
      return {
        ...prevState,
        rows: updatedRows,
      };
    });
  }, []);
  

  // const addRow = useCallback(() => {
  //   const newRow = { title: 'Other', id: Date.now(), data: { rig: 0, travel: 0, nptJmr: 0, nptCust: 0 } };
  //   dispatch({ type: 'ADD_TIME_RECORD', payload: newRow });
  // }, [dispatch]);

  const totals = timeRecordState.rows.reduce((acc, row) => ({
    rig: acc.rig + row.data.rig,
    travel: acc.travel + row.data.travel,
    nptJmr: acc.nptJmr + row.data.nptJmr,
    nptCust: acc.nptCust + row.data.nptCust,
  }), { rig: 0, travel: 0, nptJmr: 0, nptCust: 0 });
  
  // Calculate the total separately
  const total = parseFloat(totals.rig + totals.travel + totals.nptJmr + totals.nptCust).toFixed(2);

  const handleTRModalSelections = useCallback((selections) => {
    createTimeRecordsFromSelectedRoles(selections);
    handleCloseTRModal();
  }, [createTimeRecordsFromSelectedRoles]);

  const roleCounts = timeRecordState.rows.reduce((acc, row) => {
    acc[row.title] = (acc[row.title] || 0) + 1;
    return acc;
  }, {});

  const selectableRoles = timeRecordState.defaultRoles.filter((defaultRole) => {
    return !timeRecordState.selectedRoles.some((selectedRole) => selectedRole.title === defaultRole.title);
  });


  const handleDeleteTimeRecord = (deletedTimeEntry) => {
    // console.log('TimeRecord - Received deletion request for:', deletedTimeEntry);
    // console.log('TimeRecord - Current state:', timeRecordState.rows);
    // console.log('Example existing row structure:', timeRecordState.rows[0]);

    const updateUI = () => {
      // Find the row that matches the etrId or id
      const rowToDelete = timeRecordState.rows.find(row => 
        (deletedTimeEntry.etrId && row.data.etrId === deletedTimeEntry.etrId) || // match by etrId for existing records
        row.id === deletedTimeEntry.id // match by id for new records
      );

      if (!rowToDelete) {
        console.error('Could not find row to delete:', deletedTimeEntry);
        return;
      }

      // console.log('TimeRecord - Updating UI for deletion:', {
      //   rowToDelete,
      //   currentRows: timeRecordState.rows.map(row => ({ id: row.id, etrId: row.data.etrId, role: row.title }))
      // });

      setTimeRecordState((prevState) => {
        const filteredRows = prevState.rows.filter(row => 
          (deletedTimeEntry.etrId && row.data.etrId !== deletedTimeEntry.etrId) || // filter by etrId for existing records
          (!deletedTimeEntry.etrId && row.id !== deletedTimeEntry.id) // filter by id for new records
        );

        // console.log('TimeRecord - After filtering:', {
        //   beforeCount: prevState.rows.length,
        //   afterCount: filteredRows.length,
        //   deletedEntry: deletedTimeEntry,
        //   remainingIds: filteredRows.map(row => ({ id: row.id, etrId: row.data.etrId }))
        // });

        return {
          ...prevState,
          rows: filteredRows,
          selectedRoles: prevState.selectedRoles.filter((role) => role.title !== deletedTimeEntry.title),
        };
      });
    };

    // If it's an existing record (has etrId), delete from backend first
    if (deletedTimeEntry.etrId) {
      // console.log('TimeRecord - Deleting existing record from backend:', deletedTimeEntry.etrId);
      
      axios.delete(`${appUrl}/jmrapp/employeetimerecords/${deletedTimeEntry.etrId}/`, {
        headers: { 'Authorization': `Bearer ${token}` },
      })
        .then(() => {
          // console.log('TimeRecord - Backend deletion successful');
          updateUI(); // Only update UI after successful deletion
        })
        .catch((error) => {
          console.error('TimeRecord - Backend deletion failed:', error);
        });
    } else {
      // console.log('TimeRecord - Deleting new (unsaved) record');
      updateUI();
    }
  };

  // const handleDeleteTimeRecord = (deletedTimeEntry) => {
  //   console.log('Deleted time entry:', deletedTimeEntry);
  
  //   setTimeRecordState((prevState) => {
  //     console.log('Previous state rows:', prevState.rows);
  
  //     const updatedRows = prevState.rows.filter((row) => JSON.stringify(row.data) !== JSON.stringify(deletedTimeEntry));
  //     const updatedSelectedRoles = prevState.selectedRoles.filter((role) => role.title !== deletedTimeEntry.title);
  
  //     console.log('Updated rows after deletion:', updatedRows);
  
  //     if (deletedTimeEntry.etrId) {
  //       axios.delete(`http://localhost/jmrapp/employeetimerecords/${deletedTimeEntry.etrId}/`, {
  //         headers: { 'Authorization': `Bearer ${token}` },
  //       })
  //         .then(() => {
  //           console.log('Employee time record deleted successfully');
  //         })
  //         .catch((error) => {
  //           console.error('Failed to delete employee time record:', error);
  //         });
  //     }
  
  //     return {
  //       ...prevState,
  //       rows: updatedRows,
  //       selectedRoles: updatedSelectedRoles,
  //     };
  //   });
  // };
  

  


  

  return (
    <div>
      <button onClick={handleOpenTRModal} disabled={disabled}>Choose Time Records</button>
      <TimeRecordModal
        isOpen={isTRModalOpen}
        onClose={handleCloseTRModal}
        onSelect={handleTRModalSelections}
        roles={selectableRoles}
        selectedRoles={timeRecordState.selectedRoles}
        disabled={disabled}
      />
      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%', minWidth: 25 }}>Crew</th>
            <th style={{ width: '5%', minWidth: 25 }}>Employee Name</th>
            <th style={{ width: '10%', minWidth: 25 }}>Start</th>
            <th style={{ width: '10%', minWidth: 25 }}>Stop</th>
            <th style={{ width: '10%', minWidth: 25 }}>Rig</th>
            <th style={{ width: '10%', minWidth: 25 }}>Travel</th>
            <th style={{ width: '10%', minWidth: 25 }}>NPT JMR</th>
            <th style={{ width: '10%', minWidth: 25 }}>NPT Cust.</th>
            <th style={{ width: '10%', minWidth: 25 }}>Total</th>
            <th style={{ width: '10%', minWidth: 25 }}>Delete Item</th>
          </tr>
        </thead>
        <TableBody>
          {timeRecordState.rows.map((row) => (
            <TimeRecordRow
              key={row.id}
              title={roleToTitleMap[row.title] || row.title}
              employees={employees}
              data={{ ...row.data, rigData }}
              onRowChange={(updatedData) => handleRowChange(row.id, updatedData)}
              onDeleteTimeRecord={handleDeleteTimeRecord}
              disabled={disabled}
            />
          ))}
          <TableRow>
            <TableCell style={{ width: '10%', minWidth: 25 }}>Total</TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}></TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}></TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}></TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}>{totals.rig}</TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}>{totals.travel}</TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}>{totals.nptJmr}</TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}>{totals.nptCust}</TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}>{total}</TableCell>
            <TableCell style={{ width: '10%', minWidth: 25 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {selectedDwr && (
        <button onClick={submitTimeRecords} disabled={disabled}>Update Time Records</button>
      )}
    </div>
  );
});

TimeRecord.propTypes = {
  employees: PropTypes.array.isRequired,
  existingETRs: PropTypes.array,
  selectedDwr: PropTypes.object,
  disabled: PropTypes.bool,
  rigData: PropTypes.object,
};

export default TimeRecord;
