import React, { useState, useEffect, useCallback } from 'react';
import { useAuth, CACHED_WELLS } from '../api/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import WellRows from './WellRows';
import SubprojectRows from './SubprojectRows';
import CreateWellForm from './CreateWellForm';
import CreateBatteryWellForm from './CreateBatteryWellForm';
import NPTReportView from './NPTReportView';
import DWRListView from './DWRList';
import '../styles/ListView.css';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CreateContactForm from './CreateContactForm';
import PriceBookView from './PriceBookView';
import QueryView from './QueryView';

// Configuration variable
const ALWAYS_REFRESH = true; // Set to true to always fetch from the database, false to use cache

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const ListView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onLogout, authState, isSuperuser } = useAuth();
  const [wells, setWells] = useState([]);
  const [subprojects, setSubprojects] = useState([]);
  const [rig, setRig] = useState();
  const [activeTab, setActiveTab] = useState('wells');
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateWellForm, setShowCreateWellForm] = useState(false);
  const [showCreateBatteryWellForm, setShowCreateBatteryWellForm] = useState(false);
  const [showCreateContactForm, setShowCreateContactForm] = useState(false);
  const [selectedDwr, setSelectedDwr] = useState(null);
  const isManagerOrSuperuser = authState.user && (authState.user.is_superuser || authState.user.is_manager);
  const isDistrictManagerOrSuperuser = authState.user && (authState.user.is_superuser || authState.user.is_district_manager);

  const updatedWell = location.state?.updatedWell;

  const fetchWellsData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/wells/basic/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("Axios response data:", response.data);
      console.log('AUTHSTATE User : ', authState.user);

      let filteredWells = response.data;
      console.log(filteredWells);

      setWells(filteredWells);
      localStorage.setItem(CACHED_WELLS, JSON.stringify(filteredWells));
    } catch (error) {
      console.error('Error fetching wells:', error);
    }
    setIsLoading(false);
  };

  const fetchSubprojectsData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/subprojects/basic/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("Subprojects data:", response.data);
      setSubprojects(response.data);
    } catch (error) {
      console.error('Error fetching subprojects:', error);
    }
    setIsLoading(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedDwr(null); // Reset selected DWR when changing tabs
    
    // Force refresh the data when changing tabs
    if (tab === 'wells') {
      fetchWellsData();
    } else if (tab === 'subprojects') {
      fetchSubprojectsData();
    } else if (tab === 'dwrs') {
      // Force a remount of DWRListView by adding a key prop with current timestamp
      const timestamp = new Date().getTime();
      setDwrListKey(timestamp);
    }
  };

  const [dwrListKey, setDwrListKey] = useState(new Date().getTime());

  const handleDwrSelect = (dwr) => {
    setSelectedDwr(dwr);
  };

  useEffect(() => {
    if (location.state && location.state.forceReset) {
      setActiveTab('wells');
    }
  }, [location.state]);

  const selectWell = async (well) => {
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/wells/${well.id}/detailed/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Detailed Well Response :', response.data);
      navigate('/well', { state: { well: response.data } });
    } catch (error) {
      console.error('Error fetching detailed well data:', error);
    }
  };

  const handleWellUpdated = useCallback((updatedWell) => {
    setWells((prevWells) =>
      prevWells.map((well) => (well.id === updatedWell.id ? updatedWell : well))
    );
  }, []);

  useEffect(() => {
    if (updatedWell) {
      setWells((prevWells) =>
        prevWells.map((well) => (well.id === updatedWell.id ? updatedWell : well))
      );
    }
  }, [updatedWell]);

  useEffect(() => {
    if (authState.authenticated) {
      if (ALWAYS_REFRESH) {
        fetchWellsData();
      } else {
        const cachedWells = localStorage.getItem(CACHED_WELLS);
        if (cachedWells) {
          setWells(JSON.parse(cachedWells));
          setIsLoading(false);
        } else {
          fetchWellsData();
        }
      }
    }
  }, [authState.authenticated]);

  useEffect(() => {
    if (!authState.authenticated) {
      setWells([]);
    }
  }, [authState.authenticated]);

  useEffect(() => {
    if (authState.authenticated && activeTab === 'subprojects') {
      fetchSubprojectsData();
    }
  }, [authState.authenticated, activeTab]);

  const handleCreateWellClick = () => {
    setShowCreateWellForm(true);
  };

  const handleCreateWellClose = () => {
    setShowCreateWellForm(false);
  };

  const handleCreateWellSuccess = (newWell) => {
    setWells((prevWells) => [...prevWells, newWell]);
  };

  const handleCreateBatteryWellClick = () => {
    setShowCreateBatteryWellForm(true);
  };

  const handleCreateBatteryWellClose = () => {
    setShowCreateBatteryWellForm(false);
  };

  const handleCreateBatteryWellSuccess = (newWell) => {
    setWells((prevWells) => [...prevWells, newWell]);
  };

  const handleCreateContactClick = () => {
    setShowCreateContactForm(true);
  };

  const handleCreateContactClose = () => {
    setShowCreateContactForm(false);
  };

  const handleCreateContactSuccess = (newContact) => {
    console.log('New contact created:', newContact);
  };

  return (
    <>
      <div className='items'>
        <ul className='list-tab'>
          <li className={activeTab === 'wells' ? 'active' : ''} onClick={() => handleTabClick('wells')}>Wells</li>
          <li className={activeTab === 'subprojects' ? 'active' : ''} onClick={() => handleTabClick('subprojects')}>Subprojects</li>
          {isManagerOrSuperuser && (
            <li className={activeTab === 'dwrs' ? 'active' : ''} onClick={() => handleTabClick('dwrs')}>DWRs</li>
          )}
          {isManagerOrSuperuser && (
            <li className={activeTab === 'npt-reporting' ? 'active' : ''} onClick={() => handleTabClick('npt-reporting')}>NPT Reporting</li>
          )}
          {isDistrictManagerOrSuperuser && (
            <li className={activeTab === 'queries' ? 'active' : ''} onClick={() => handleTabClick('queries')}>Custom Reports</li>
          )}
          {isManagerOrSuperuser && (
            <li style={{ marginLeft: 'auto' }}>
              <button className="btn create-contact-button" onClick={handleCreateContactClick}>Create Contact</button>
              <button className="btn create-well-button" onClick={handleCreateWellClick}>Create Well</button>
              <button className="btn create-battery-well-button" onClick={handleCreateBatteryWellClick}>Create Battery Well</button>
            </li>
          )}
        </ul>
      </div>
      {showCreateContactForm && (
        <CreateContactForm
          onClose={handleCreateContactClose}
          onContactCreated={handleCreateContactSuccess}
        />
      )}
      {showCreateWellForm && (
        <CreateWellForm
          onClose={handleCreateWellClose}
          onWellCreated={handleCreateWellSuccess}
        />
      )}
      {showCreateBatteryWellForm && (
        <CreateBatteryWellForm
          onClose={handleCreateBatteryWellClose}
          onWellCreated={handleCreateBatteryWellSuccess}
        />
      )}
      {activeTab === 'wells' && (isLoading ? <p>Loading...</p> : <WellRows data={wells} onSelectWell={selectWell} />)}
      {activeTab === 'subprojects' && (isLoading ? <p>Loading...</p> : <SubprojectRows data={subprojects} onSelectWell={selectWell} />)}
      {activeTab === 'dwrs' && isManagerOrSuperuser && (
        <DWRListView 
          key={dwrListKey}
          selectedDwr={selectedDwr}
          onDwrSelect={handleDwrSelect}
        />
      )}
      {activeTab === 'npt-reporting' && isManagerOrSuperuser && <NPTReportView />}
      {activeTab === 'queries' && isDistrictManagerOrSuperuser && <QueryView />}
    </>
  );
};

export default ListView;
