import {useState} from 'react'
import { useAuth } from '../api/AuthContext';
import { useNavigate } from 'react-router-dom'; 
import logo from '../assets/JMR-Logo.png'
import '../styles/Login.css';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const {onLogin} = useAuth()
    const navigate = useNavigate()

    const loginHandler = async (event) => {
        event.preventDefault()
        setLoading(true)

        try {
            const result = await onLogin(email.toLowerCase(), password);
            if (result.data && result.data.access) {
                // No need to manage user here. AuthContext will handle it.
                setLoading(false);
                navigate('/'); // Navigate to the homepage or dashboard after successful login.
            } else {
                throw new Error('Authentication failed.');
            }
        } catch (error) {
            console.error(error);
            alert('Login Failed: Please check your credentials');
            setLoading(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            loginHandler();
        }
    };

  return (
    <div className="login-container">
        <form className="login-form" onSubmit={loginHandler}>
            <img className="logo" src={logo} alt="Logo" />
            <label htmlFor="email">Email Address</label>
            <input
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
            />
            <label htmlFor="password">Password</label>
            <input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
            />
            <button disabled={loading} type='submit'>
                {loading ? 'Loading...' : 'Sign In'}
            </button>
        </form>
    </div>
  )
}

export default Login
