import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { PDFDocument } from 'pdf-lib';

const formatLongDate = (dateStr) => {
  const date = new Date(dateStr + 'T12:00:00Z');
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  }).replace(/(\d+)(?=(st|nd|rd|th))/, (match) => {
    const day = parseInt(match);
    const suffix = ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10];
    return `${day}${suffix}`;
  });
};

export const exportRigPointsReportAsPDF = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true,
        putOnlyUsedFonts: true,
        precision: 2,
        margins: { top: 10, bottom: 10, left: 10, right: 10 }
      });

      // Set up document properties
      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      doc.text('Rig Points Report', 10, 20);

      // Add date range
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`Report Period: ${formatLongDate(data.date_range.from_date)} to ${formatLongDate(data.date_range.to_date)}`, 10, 30);
      
      // Add rig filter if present
      if (data.rig_filter && data.rig_filter !== 'All Rigs') {
        doc.text(`Rig: ${data.rig_filter}`, 10, 40);
      }

      // Add summary section
      doc.setFontSize(12);
      doc.text('Summary:', 10, 50);
      doc.text(`Total Points: ${Number(data.summary.total_points).toLocaleString('en-US', { minimumFractionDigits: 1 })}`, 20, 60);
      doc.text(`Total Work Days: ${data.summary.total_work_days}`, 20, 70);
      doc.text(`Average Crew Hours: ${Number(data.summary.avg_hours_overall).toLocaleString('en-US', { minimumFractionDigits: 2 })}`, 20, 80);

      // Create the main data table
      const tableData = data.results.map(row => ({
        rig: row.rig,
        subproject: row.subproject,
        total_work_days: row.total_work_days,
        total_points: Number(row.total_points).toLocaleString('en-US', { minimumFractionDigits: 1 }),
        mode_crew_hours: Number(row.mode_crew_hours).toLocaleString('en-US', { minimumFractionDigits: 1 })
      }));

      doc.autoTable({
        startY: 90,
        head: [['Rig', 'Subproject', 'Work Days', 'Points', 'Mode Crew Hours']],
        body: tableData.map(row => [
          row.rig,
          row.subproject,
          row.total_work_days,
          row.total_points,
          row.mode_crew_hours
        ]),
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontStyle: 'bold',
          halign: 'left'
        },
        bodyStyles: {
          textColor: [0, 0, 0],
          fontSize: 10,
          cellPadding: 3
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
          2: { cellWidth: 'auto', halign: 'right' },
          3: { cellWidth: 'auto', halign: 'right' },
          4: { cellWidth: 'auto', halign: 'right' }
        },
        theme: 'grid',
        styles: {
          fontSize: 10,
          cellPadding: 3,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          minCellHeight: 10,
          fillColor: [255, 255, 255]
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255]
        },
        margin: { top: 10, right: 10, bottom: 10, left: 10 }
      });

      // Add page numbers
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
      }

      // Create final compressed blob
      const pdfBlob = doc.output('blob');
      const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
      const compressedPdfBytes = await pdfDoc.save({
        useObjectStreams: true,
        addDefaultPage: false,
        useCompression: true
      });
      const finalPdfBlob = new Blob([compressedPdfBytes], { type: 'application/pdf' });

      // Download the PDF
      const url = URL.createObjectURL(finalPdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Rig Points Report ${formatLongDate(data.date_range.from_date)} to ${formatLongDate(data.date_range.to_date)}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      resolve(finalPdfBlob);
    } catch (error) {
      console.error('Error generating PDF:', error);
      reject(error);
    }
  });
}; 