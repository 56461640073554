import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import WellForm from "./WellForm";
import WorkAssignment from "./WorkAssignment";
import WorkDescriptionsModal from "./WorkDescriptionsModal";
import ChargeRecordModal from "./ChargeRecordModal";
import ChargeRecord from "./ChargeRecord";
import TimeRecord from "./TimeRecord";
import TimeRecordModal from "./TimeRecordModal";
import { useAuth } from "../api/AuthContext";
import "../styles/DWRForm.css";
import "../styles/WellForm.css";
import { convertTo24Hour } from "./convertTo24Hour";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { createAndUploadCSV } from "./create_and_upload_csv";
import { createXLSX } from './create_and_download_xlsx';
import DynamicWorkDescription from './DynamicWorkDescription';
import DWRNavigationHeader from "./DWRNavigationHeader";


// Material UI imports - import each component separately
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

// Material UI Icons - import each icon separately
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import SubProjectSelector from './SubProjectSelector';
import SubProjects from './SubProjects';


const appUrl = process.env.REACT_APP_APP_URL;
if (!appUrl) {
  throw new Error(
    "REACT_APP_APP_URL is not defined. Please set it in your .env file."
  );
}

// Add test variables for ADP integration
const ADP_test_rig = "";
const ADP_test_region = "Deleware Basin";

const DWRForm = ({ selectedDwr, setSelectedDwr, onClose, getAdjacentDWR, subprojectType, subprojectId, subprojectNsId, fetchFullDWRData  }) => {
  const { authState } = useAuth();
  const currentUser = authState?.user;
  const token = authState?.token;
  const location = useLocation();
  const well = location.state?.well;
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingSalesOrder, setIsCreatingSalesOrder] = useState(false);

  const initialFormState = {
    id: null,
    date: "",
    day: "",
    isLastDay: "",
    ticketNumber: "",
    contact: null,
    workAssignments: [],
    workDescriptions: [],
    chargeRecords: {
      onTurnkey: [],
      offTurnkey: [],
    },
    timeRecords: [],
    subproject_type: null,
    subproject_id: null,
    subproject_netsuite_id: null
  };

  const [formData, setFormData] = useState(initialFormState);
  const [workAssignments, setWorkAssignments] = useState([]);
  const [isLoadingWorkDescriptions, setIsLoadingWorkDescriptions] = useState(false);
  const [workDescriptions, setWorkDescriptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rigData, setRigData] = useState(null);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedInventoryObjects, setSelectedInventoryObjects] = useState([]);
  const [selectedServiceObjects, setSelectedServiceObjects] = useState([]);
  const [isChargeModalOpen, setIsChargeModalOpen] = useState({
    onTurnkey: false,
    offTurnkey: false,
  });
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [selectedServiceItems, setSelectedServiceItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [onTurnkeyChargeItems, setOnTurnkeyChargeItems] = useState([]);
  const [offTurnkeyChargeItems, setOffTurnkeyChargeItems] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [workDescAssignments, setWorkDescAssignments] = useState({});
  const [currentDwrId, setCurrentDwrId] = useState(null);
  const [chargeRecordIds, setChargeRecordIds] = useState({
    onTurnkey: null,
    offTurnkey: null,
  });
  const [timeRecordId, setTimeRecordId] = useState(null);
  const [timeRecordUpdateNeeded, setTimeRecordUpdateNeeded] = useState(false);
  const [showDuplicateChargeError, setShowDuplicateChargeError] = useState(false);
  const [updateChargeRecordsTriggered, setUpdateChargeRecordsTriggered] = useState(false);
  const [employeeTimeRecords, setEmployeeTimeRecords] = useState([]);
  const [timeRecordSelections, setTimeRecordSelections] = useState([]);
  const [isTimeRecordModalOpen, setIsTimeRecordModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState("");
  const [notesUpdated, setNotesUpdated] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isDwrLoading, setIsDwrLoading] = useState(true);
  const [areChargesLoading, setAreChargesLoading] = useState(true);
  const [showOnTurnkey, setShowOnTurnkey] = useState(well.name === "Chevron DJ Basin");
  const [showChargeRecordWarning, setShowChargeRecordWarning] = useState(false);
  const [initialOnTurnkeyItems, setInitialOnTurnkeyItems] = useState([]);
  const [initialOffTurnkeyItems, setInitialOffTurnkeyItems] = useState([]);
  const [hasLoadedCharges, setHasLoadedCharges] = useState(false);
  const [zeroAllPrices, setZeroAllPrices] = useState(false);
  const [currentInventoryPricebook, setCurrentInventoryPricebook] = useState(null);
  const [currentServicePricebook, setCurrentServicePricebook] = useState(null);
  const [isLoadingPricebook, setIsLoadingPricebook] = useState(false);
  const [miscellaneousItems, setMiscellaneousItems] = useState([]);
  const [day, setDay] = useState("");
  const [isLastDay, setIsLastDay] = useState(false);
  const [ticketNumber, setTicketNumeber] = useState("");
  const [isLocked, setIsLocked] = useState(selectedDwr?.is_locked || false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isApproved, setIsApproved] = useState(selectedDwr?.is_approved || false);
  const [currentWorkAssignments, setCurrentWorkAssignments] = useState([]);
  const [initialChargeRecordIds, setInitialChargeRecordIds] = useState({
    onTurnkey: null,
    offTurnkey: null
  });

  const timeRecordRef = useRef();

  const updateQuantities = (id, quantity) => {
    setQuantities((prev) => ({ ...prev, [id]: quantity }));
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const hasPermission = (permission) => {
    return currentUser && currentUser.permissions && currentUser.permissions.includes(permission);
  };

  useEffect(() => {
    if (selectedDwr) {
      setIsDwrLoading(true);
      const formattedAssignments = selectedDwr.work_assignments
      .map(assignment => ({
        id: assignment.id,
        work_description: assignment.work_description,
        workDescriptionId: assignment.work_description?.id,
        description: assignment.description,
        fromTime: assignment.fromTime || assignment.from_time || '00:00', // Check both formats
        toTime: assignment.toTime || assignment.to_time || '00:00',       // Check both formats
        input_values: assignment.input_values || {},
        is_legacy: assignment.is_legacy || !assignment.work_description?.template_text
      }))
      .sort((a, b) => {
        // Add null check before sorting
        if (!a.fromTime || !b.fromTime) return 0;
        return a.fromTime.localeCompare(b.fromTime);
      });
      setCurrentWorkAssignments(formattedAssignments); // Keep track of original state
      setIsDwrLoading(false);
    } else {
      setWorkAssignments([]);
      setCurrentWorkAssignments([]);
    }
  }, [selectedDwr]);
  

  useEffect(() => {
    if (selectedDwr) {
      setIsDwrLoading(true);
      setAreChargesLoading(true);
      setIsLocked(selectedDwr.is_locked);
      setIsApproved(selectedDwr.is_approved);
      setIsApproved(selectedDwr.approval_status === 'approved');
      console.log("SELECTED DWR: ", selectedDwr);
      console.log("Charge Records:", selectedDwr.charge_records);
      const onTurnkeyCharges = selectedDwr.charge_records.filter(
        (record) => record.on_turnkey
      );
      const offTurnkeyCharges = selectedDwr.charge_records.filter(
        (record) => !record.on_turnkey
      );

        // Step 2: Update zeroAllPrices based on the condition
        if (offTurnkeyCharges.length === 0 && onTurnkeyCharges.length > 0) {
          setZeroAllPrices(true);
      } else {
          setZeroAllPrices(false);
      }

      setFormData({
        id: selectedDwr.id,
        date: selectedDwr.date,
        day: selectedDwr.day, // Add day to initial form state
        isLastDay: selectedDwr.isLastDay,
        ticketNumber: selectedDwr.ticket_number,
        is_locked: selectedDwr.is_locked,
        is_approved: selectedDwr.is_approved,
        notes: selectedDwr.notes || "",
        contact_id: selectedDwr.contact ? selectedDwr.contact.id : null,
        chargeRecords: {
          onTurnkey: onTurnkeyCharges,
          offTurnkey: offTurnkeyCharges,
        },
        subproject_type: subprojectType,
        subproject: subprojectId,
        subproject_netsuite_id: subprojectNsId
      });
      setDay(selectedDwr.day)
      setTicketNumeber(selectedDwr.ticketNumber)
      setNotes(selectedDwr.notes || "");
      setNotesUpdated(false);
      setIsLastDay(Boolean(selectedDwr.is_last_day));
      setIsLocked(selectedDwr.is_locked);
      setIsApproved(selectedDwr.is_approved);


      // Set charge record IDs
      setChargeRecordIds({
        onTurnkey: onTurnkeyCharges.length > 0 ? onTurnkeyCharges[0].id : null,
        offTurnkey:
          offTurnkeyCharges.length > 0 ? offTurnkeyCharges[0].id : null,
      });

      // setWorkAssignments(
      //   selectedDwr.work_assignments
      //     .map((assignment) => ({
      //       ...assignment,
      //       description: assignment.description,
      //       fromTime: assignment.from_time,
      //       toTime: assignment.to_time,
      //     }))
      //     .sort((a, b) => a.fromTime.localeCompare(b.fromTime))
      // );

      setIsDwrLoading(false);
    } else {
      setFormData(initialFormState);
      setDay("")
      setNotes("");
      setTicketNumeber("");
      setNotesUpdated(false);
      // setWorkAssignments([]);
      setIsDwrLoading(false);
      setAreChargesLoading(false);
    }

    console.log("SELECTED DWR: ", selectedDwr);
  }, [selectedDwr]);

  useEffect(() => {
    if (selectedDwr) {
      // When a DWR is first loaded, set the initial charge record IDs
      setInitialChargeRecordIds({
        onTurnkey: chargeRecordIds.onTurnkey,
        offTurnkey: chargeRecordIds.offTurnkey
      });
    }
  }, [selectedDwr]);


  useEffect(() => {
    const fetchContacts = async () => {
      try {
        if (!well) {
          return;
        }

        const response = await axios.get(appUrl + `/jmrapp/contacts/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredContacts = response.data.filter(
          (contact) => contact.company_id === well.customer.ns_iid
        );

        setContacts(filteredContacts);
      } catch (error) {
        confirmAlert({
          title: "Error",
          message: "Failed to fetch contacts. Please try again.",
          buttons: [{ label: "OK", onClick: () => {} }],
        });
      }
    };

    fetchContacts();
  }, [token, well]);

// Modify handleUploadCSVClick in DWRForm.js:
const handleUploadCSVClick = async () => {
  if (!selectedDwr || isLocked) {
    return;
  }

  setIsCreatingSalesOrder(true);
  try {
    let currentSubproject = null;

    if (selectedDwr.subproject && selectedDwr.subproject.id) {
      currentSubproject = selectedDwr.subproject;
    } 
    else if (selectedDwr.well?.subprojects?.length > 0) {
      if (formData.subproject) {
        currentSubproject = selectedDwr.well.subprojects.find(sub => 
          sub.id === formData.subproject
        );
      }
      else {
        currentSubproject = selectedDwr.well.subprojects.find(sub => 
          sub.is_active === true
        ) || selectedDwr.well.subprojects[0];
      }
    }

    if (!currentSubproject) {
      confirmAlert({
        title: "Error",
        message: "No subproject information found. Please ensure a subproject is selected.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
      return;
    }

    const dwrWithSubprojectInfo = {
      ...selectedDwr,
      subproject: {
        id: currentSubproject.id,
        name: currentSubproject.name,
        type: currentSubproject.subproject_type,
        type_name: currentSubproject.subproject_type_name,
        netsuite_id: currentSubproject.netsuite_id,
        project: currentSubproject.project
      },
      well: {
        ...selectedDwr.well,
        subproject_id: currentSubproject.id,
        subproject_name: currentSubproject.name,
        subproject_type_name: currentSubproject.subproject_type_name,
        subproject_netsuite_id: currentSubproject.netsuite_id
      }
    };

    const csvSuccess = await createAndUploadCSV(dwrWithSubprojectInfo, zeroAllPrices, authState);

    if (csvSuccess) {
      const updateResponse = await axios.patch(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        {
          create_sales_order: true,
          is_locked: true,
          is_last_day: isLastDay,
          subproject: currentSubproject.id,
          subproject_netsuite_id: currentSubproject.netsuite_id
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      await handleManualLock();
      setIsLocked(true);
      setSelectedDwr(prevDwr => ({
        ...prevDwr,
        is_locked: true,
        so_created_at: updateResponse.data.so_created_at,
        is_last_day: isLastDay,
        subproject: {
          id: currentSubproject.id,
          type: currentSubproject.subproject_type,
          type_name: currentSubproject.subproject_type_name,
          netsuite_id: currentSubproject.netsuite_id
        }
      }));

      confirmAlert({
        title: "Success",
        message: "Sales order created and DWR locked successfully.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
    } else {
      confirmAlert({
        title: "Error",
        message: "Failed to create sales order or lock DWR. Please try again.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
    }
  } catch (error) {
    confirmAlert({
      title: "Error",
      message: `Failed to create sales order or lock DWR: ${error.message}`,
      buttons: [{ label: "OK", onClick: () => {} }],
    });
  } finally {
    setIsCreatingSalesOrder(false);
  }
};

  // Function to handle CSV upload button click
  const handleCreateXSLXClick = () => {
    const data = selectedDwr || formData;
    createXLSX(data, zeroAllPrices, authState, '/open_invoice_template.xlsx');
  };

  useEffect(() => {
    if (!selectedDwr || isDwrLoading) return;
    
    let isSubscribed = true;
    
    const fetchItemDetails = async () => {
      if (!selectedDwr.charge_records.length || hasLoadedCharges) return;
      
      setAreChargesLoading(true);
  
      try {
        const chargeRecordPromises = selectedDwr.charge_records.map(async (chargeRecord) => {
          const [serviceItemDetails, inventoryItemDetails, miscellaneousItemDetails] = await Promise.all([
            Promise.all(
              chargeRecord.service_item_charges.map(charge => 
                axios.get(`${appUrl}/jmrapp/serviceitems/${charge.service_item}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }).then(response => ({
                  chargeId: charge.id,
                  id: charge.service_item,
                  type: "Service",
                  quantity_used: parseFloat(charge.quantity_used),
                  name: response.data.name,
                  unit_type: response.data.unit_type,
                  quantity_available: "N/A",
                  price: response.data.price,
                  endpoint_id: response.data.endpoint_id,
                }))
              )
            ),
            Promise.all(
              chargeRecord.inventory_item_charges.map((charge) =>
                axios.get(`${appUrl}/jmrapp/inventoryitems/${charge.inventory_item}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }).then((response) => ({
                  chargeId: charge.id,
                  id: charge.inventory_item,
                  type: "Inventory",
                  quantity_used: parseFloat(charge.quantity_used),
                  name: response.data.name,
                  unit_type: response.data.unit_type,
                  quantity_available: response.data.quantity_available,
                  price: response.data.price,
                  ns_eid: response.data.ns_eid,
                }))
              )
            ),
            Promise.all(
              chargeRecord.miscellaneous_item_charges.map((charge) =>
                axios.get(`${appUrl}/jmrapp/miscellaneous-item/${charge.miscellaneous_item}/`, {
                  headers: { Authorization: `Bearer ${token}` },
                }).then((response) => ({
                  chargeId: charge.id,
                  id: response.data.id, // Use the id from the API response
                  type: "Miscellaneous",
                  quantity_used: parseFloat(charge.quantity_used),
                  name: charge.custom_name,
                  unit_type: response.data.unit_type || "N/A",
                  price: parseFloat(charge.price_at_use),
                  endpoint_id: response.data.endpoint_id,
                  price_at_use: parseFloat(charge.price_at_use),
                }))
              )
            ),
          ]);
  
          return {
            on_turnkey: chargeRecord.on_turnkey,
            items: [...serviceItemDetails, ...inventoryItemDetails, ...miscellaneousItemDetails],
          };
        });
  
        const detailedChargeRecords = await Promise.all(chargeRecordPromises);
        
        if (!isSubscribed) return;
  
        const onTurnkeyItems = detailedChargeRecords
          .filter(record => record.on_turnkey)
          .flatMap(record => record.items);
        const offTurnkeyItems = detailedChargeRecords
          .filter(record => !record.on_turnkey)
          .flatMap(record => record.items);
  
        setOnTurnkeyChargeItems(onTurnkeyItems);
        setOffTurnkeyChargeItems(offTurnkeyItems);
        setInitialOnTurnkeyItems(onTurnkeyItems);
        setInitialOffTurnkeyItems(offTurnkeyItems);
        setHasLoadedCharges(true);
  
      } catch (error) {
        console.error("Failed to fetch item details:", error);
        if (isSubscribed) {
          confirmAlert({
            title: "Error",
            message: "Failed to load charge items. Please try again.",
            buttons: [{ label: "OK" }],
          });
        }
      } finally {
        if (isSubscribed) {
          setAreChargesLoading(false);
        }
      }
    };
  
    const initializeWorkAssignments = () => {
      if (!isSubscribed || !selectedDwr?.work_assignments) return;
      
      setWorkAssignments(
        selectedDwr.work_assignments
          .map(assignment => ({
            id: assignment.id,
            work_description: assignment.work_description,
            workDescriptionId: assignment.work_description?.id,
            description: assignment.description,
            fromTime: assignment.fromTime || assignment.from_time || '00:00', // Check both formats
            toTime: assignment.toTime || assignment.to_time || '00:00',       // Check both formats
            input_values: assignment.input_values || {},
            is_legacy: assignment.is_legacy || !assignment.work_description?.template_text
          }))
          .sort((a, b) => {
            const timeA = a.fromTime || '';  
            const timeB = b.fromTime || '';
            return timeA.localeCompare(timeB);
          })
      );
      setCurrentWorkAssignments(selectedDwr.work_assignments); // Track original state
    };
  
    fetchItemDetails();
    initializeWorkAssignments();
  
    return () => {
      isSubscribed = false;
    };
  }, [selectedDwr, token, appUrl, hasLoadedCharges, isDwrLoading]);



  // Reset hasLoadedCharges when selectedDwr changes
  useEffect(() => {
    setHasLoadedCharges(false);
  }, [selectedDwr]);

  const memoizedEmployeeTimeRecords = useMemo(() => {
    return selectedDwr &&
      selectedDwr.time_record &&
      selectedDwr.time_record.employee_time_records
      ? selectedDwr.time_record.employee_time_records
      : [];
  }, [selectedDwr?.time_record?.employee_time_records?.length]);

  const rows = timeRecordRef.current ? timeRecordRef.current.getRowsData() : [];

  useEffect(() => {
    if (!isSubmitClicked) return;
  
    const prepareAndSubmitDWR = async () => {
      const formDataPayload = {
        date: formData.date,
        ticket_number: formData.ticketNumber,  // Changed to match backend field name
        well_id: well.id,
        subproject_type: subprojectType,  // Use prop passed to component
        subproject: subprojectId,         // Use prop passed to component
        subproject_netsuite_id: subprojectNsId,
        notes: notes,
        day: formData.day,
        is_last_day: formData.isLastDay || false,
        contact_id: formData.contact_id || null,
        ...(currentUser ? { created_by: currentUser.id } : {}),
      };
      console.log("DATA TO CREATE WELL: ", formDataPayload);
  
      const isUpdating = currentDwrId !== null;
      const method = isUpdating ? "put" : "post";
      const url = isUpdating
        ? `${appUrl}/jmrapp/dailyworkrecords/${currentDwrId}/`
        : `${appUrl}/jmrapp/dailyworkrecords/`;
  
      try {
        const response = await axios({
          method: method,
          url: url,
          data: formDataPayload,
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const newDwr = response.data;
        setSelectedDwr(newDwr);
        const dwrIdCreated = newDwr.id;
        setCurrentDwrId(dwrIdCreated);

        if (!isUpdating) {
          const createChargeRecord = async (onTurnkey) => {
            const chargeItems = onTurnkey ? onTurnkeyChargeItems : offTurnkeyChargeItems;
            
            console.log(`Creating ${onTurnkey ? 'on' : 'off'} turnkey charge record for DWR ${dwrIdCreated}`);
            console.log(`${onTurnkey ? 'On' : 'Off'} Turnkey Charge Items:`, chargeItems);
            console.log('****CHARGE ITEMS****:', chargeItems);
            const totalAmount = chargeItems.reduce((sum, item) => {
              console.log('****ITEM****:', item);
              return sum + (item.price * item.quantity_used);
            }, 0);

            const payload = {
              dwr: dwrIdCreated,
              on_turnkey: onTurnkey,
              total_amount: totalAmount, // Add this line
              service_item_charges: chargeItems
                .filter(item => item.type === 'Service')
                .map(item => ({
                  service_item_id: item.id,
                  quantity_used: item.quantity_used,
                  price_at_use: zeroAllPrices ? 0 : Number(item.price || 0)  // This was missing
                })),
              inventory_item_charges: chargeItems
                .filter(item => item.type === 'Inventory')
                .map(item => ({
                  inventory_item_id: item.id,
                  quantity_used: item.quantity_used,
                  price_at_use: zeroAllPrices ? 0 : Number(item.price || 0)  // This was missing
                })),
              miscellaneous_item_charges: chargeItems
                .filter(item => item.type === 'Miscellaneous')
                .map(item => ({
                  miscellaneous_item_id: item.id,
                  quantity_used: item.quantity_used,
                  custom_name: item.custom_name,
                  price_at_use: item.price_at_use
                }))
            };
    
            console.log(`${onTurnkey ? 'On' : 'Off'} Turnkey Charge Record Payload:`, payload);
    
            try {
              const response = await axios.post(
                `${appUrl}/jmrapp/chargerecords/`,
                payload,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              console.log(`${onTurnkey ? 'On' : 'Off'} turnkey charge record created with ID:`, response.data.id);
              return response.data.id;
            } catch (error) {
              console.error(`Error creating ${onTurnkey ? 'on' : 'off'} turnkey charge record:`, error.response?.data);
              throw error;
            }
          };
    
          let onTurnkeyChargeRecordId = null;
          let offTurnkeyChargeRecordId = null;
    
          // Check if on-turnkey items exist before creating the record
          if (onTurnkeyChargeItems.length > 0) {
            onTurnkeyChargeRecordId = await createChargeRecord(true);
          }
    
          // Check if off-turnkey items exist before creating the record
          if (offTurnkeyChargeItems.length > 0) {
            offTurnkeyChargeRecordId = await createChargeRecord(false);
          }
    
          setChargeRecordIds({
            onTurnkey: onTurnkeyChargeRecordId,
            offTurnkey: offTurnkeyChargeRecordId,
          });
    
          await updateDWRWithChildEntities(dwrIdCreated);
        } else {
          console.log("DWR updated successfully:", response.data);
          await updateDWRWithChildEntities(currentDwrId);
        }
  
        setIsSubmitClicked(false);
        setIsSubmitting(false);
  
        confirmAlert({
          title: "Success",
          message: "DWR submitted successfully.",
          buttons: [{ label: "OK", onClick: () => {} }],
        });
      } catch (error) {
        console.error("Error processing DWR:", error);
        setIsSubmitClicked(false);
        setIsSubmitting(false);
  
        confirmAlert({
          title: "Error",
          message: "Failed to submit DWR. Please try again.",
          buttons: [{ label: "OK", onClick: () => {} }],
        });
      }
    };
  
    prepareAndSubmitDWR();
  }, [isSubmitClicked]);

  async function updateDWRWithChildEntities(currentDwrId) {
    console.log(`Starting updateDWRWithChildEntities for DWR ID: ${currentDwrId}`);
    try {
      const employeeTimeRecordsData = rows;
      console.log("Employee Time Records Data:", employeeTimeRecordsData);
  
      // Handle work assignments
      const workAssignmentPromises = workAssignments.map((assignment) => {
        const workAssignmentPayload = {
          description: assignment.description,
          from_time: assignment.fromTime,
          to_time: assignment.toTime,
          dwr: currentDwrId,
          work_description_id: assignment.workDescriptionId,
          input_values: assignment.input_values,
          is_legacy: false  // Set to false for new assignments
        };
        console.log("Creating work assignment with payload:", workAssignmentPayload);
        return axios.post(
          `${appUrl}/jmrapp/workassignments/`,
          workAssignmentPayload,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      });

      // Handle time record - but don't create employee time records here
      try {
        console.log("Creating time record");
        const timeRecordResponse = await axios.post(
          `${appUrl}/jmrapp/timerecord/`,
          { dwr: currentDwrId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const timeRecordId = timeRecordResponse.data.id;
        setTimeRecordId(timeRecordId);
        console.log("Time Record created successfully:", timeRecordId);
        
        // Set timeRecordUpdateNeeded to true to trigger the useEffect that will create employee time records
        setTimeRecordUpdateNeeded(true);
      } catch (error) {
        console.error("Error creating Time Record:", error);
      }

      // Fetch existing charge records
      console.log("Fetching existing charge records for ", currentDwrId);
      const existingChargeRecordsResponse = await axios.get(
        `${appUrl}/jmrapp/chargerecords/?dwr=${currentDwrId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const existingChargeRecords = existingChargeRecordsResponse.data;
      console.log("Existing charge records:", existingChargeRecords.map(record => ({id: record.id, dwrId: record.dwr, onTurnkey: record.on_turnkey})));

  
      const newChargeRecordIds = {};
  
      // Handle charge records
      for (const type of ["onTurnkey", "offTurnkey"]) {
        console.log(`Processing ${type} charge record`);
        const chargeItems = type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
        // Check if a charge record already exists
        const existingChargeRecord = await axios.get(
          `${appUrl}/jmrapp/chargerecords/?dwr=${currentDwrId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (existingChargeRecord.data.length > 0) {
          // console.log(`${onTurnkey ? 'On' : 'Off'} turnkey charge record already exists. Updating...`);
          return existingChargeRecord.data[0].id;
        }


        const existingRecord = existingChargeRecords.find(
          (record) => record.on_turnkey === (type === "onTurnkey") && record.dwr === currentDwrId
        );
      
  
        try {
          let chargeRecordId;
  
          if (existingRecord) {
            chargeRecordId = existingRecord.id;
            console.log(`Updating existing ${type} charge record with ID: ${existingRecord.id}`);
        } else {
            console.log(`Creating new ${type} charge record for DWR ${currentDwrId}`);
            const chargeRecordResponse = await axios.post(
              `${appUrl}/jmrapp/chargerecords/`,
              {
                dwr: currentDwrId,
                on_turnkey: type === "onTurnkey",
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            chargeRecordId = chargeRecordResponse.data.id;
            console.log(`Created new ${type} charge record with ID: ${chargeRecordId}`);
          }
  
          newChargeRecordIds[type] = chargeRecordId;
  
          if (chargeItems.length > 0) {
            console.log(`Updating ${type} charge record with items:`, chargeItems);
            const serviceItemCharges = chargeItems
              .filter((item) => item.type === "Service")
              .map(({ id, quantity_used, price }) => ({
                service_item_id: id,
                quantity_used,
                price_at_use: price
              }));
  
            const inventoryItemCharges = chargeItems
              .filter((item) => item.type === "Inventory")
              .map(({ id, quantity_used, price }) => ({
                inventory_item_id: id,
                quantity_used,
                price_at_use: price
              }));
  
            console.log(`Patching ${type} charge record:`, {
              service_item_charges: serviceItemCharges,
              inventory_item_charges: inventoryItemCharges,
            });
  
            await axios.patch(
              `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
              {
                service_item_charges: serviceItemCharges,
                inventory_item_charges: inventoryItemCharges,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
  
            console.log(`${type} charge record updated successfully`);
          }
        } catch (error) {
          console.error(
            `Error processing ${type} charge record:`,
            error.response ? error.response.data : error.message
          );
        }
      }
  
      await Promise.all(workAssignmentPromises);
  
      setChargeRecordIds(newChargeRecordIds);
      console.log("All child entities updated successfully");
      console.log("New charge record IDs:", newChargeRecordIds);
    } catch (error) {
      console.error("Error updating child entities:", error);
    }
  }
  

  useEffect(() => {
    console.log("Charge record IDs changed:", chargeRecordIds);
    if (!chargeRecordIds.onTurnkey && !chargeRecordIds.offTurnkey) {
      console.log("No charge record IDs available, skipping update");
      return;
    }
  
    const updateChargeRecord = async (type) => {
      console.log(`Starting update for ${type} charge record`);
      const chargeItems =
        type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
      const initialItems =
        type === "onTurnkey" ? initialOnTurnkeyItems : initialOffTurnkeyItems;
  
      console.log(`Current ${type} items:`, chargeItems);
      console.log(`Initial ${type} items:`, initialItems);
  
      const hasChanges =
        JSON.stringify(chargeItems) !== JSON.stringify(initialItems);
  
      if (!hasChanges) {
        console.log(`No changes detected for ${type} charge records. Skipping update.`);
        return;
      }
  
      console.log(`Changes detected for ${type} charge records. Proceeding with update.`);
  
      const serviceItemCharges = chargeItems
        .filter((item) => item.type === "Service")
        .map(({ id, quantity_used, price }) => ({
          service_item_id: id,
          quantity_used,
          price_at_use: zeroAllPrices ? 0 : Number(price || 0)  // Use actual price instead of 555
        }));
  
      const inventoryItemCharges = chargeItems
        .filter((item) => item.type === "Inventory")
        .map(({ id, quantity_used, price }) => ({
          inventory_item_id: id,
          quantity_used,
          price_at_use: zeroAllPrices ? 0 : Number(price || 0)  // Use actual price instead of 555
        }));
      
      const miscellaneousItemCharges = chargeItems
        .filter((item) => item.type === "Miscellaneous")
        .map(({ id, quantity_used, name, price }) => ({
          miscellaneous_item_id: id,
          quantity_used,
          custom_name: name,
          price_at_use: parseFloat(price),
        }));
  
      // Calculate total here
      const totalAmount = chargeItems.reduce((sum, item) => {
        const price = zeroAllPrices ? 0 : parseFloat(item.price || 0);
        const quantity = parseFloat(item.quantity_used || 0);
        return sum + (price * quantity);
      }, 0);
  
      const chargeRecordId = chargeRecordIds[type];
      console.log(`Updating ${type} charge record with ID:`, chargeRecordId);
      console.log("Service item charges:", serviceItemCharges);
      console.log("Inventory item charges:", inventoryItemCharges);
      console.log("Miscellaneous item charges:", miscellaneousItemCharges);
      console.log("Total Amount:", totalAmount);
  
      try {
        const response = await axios.patch(
          `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
          {
            on_turnkey: type === "onTurnkey",
            total_amount: Number(totalAmount.toFixed(2)),
            service_item_charges: serviceItemCharges,
            inventory_item_charges: inventoryItemCharges,
            miscellaneous_item_charges: miscellaneousItemCharges,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log(`${type} ChargeRecord updated successfully:`, response.data);
      } catch (error) {
        console.error(
          `Error updating ${type} charge record with items:`,
          error.response ? error.response.data : error.message
        );
      }
    };
  
    if (chargeRecordIds.onTurnkey) {
      console.log("Updating onTurnkey charge record");
      updateChargeRecord("onTurnkey");
    }
    if (chargeRecordIds.offTurnkey) {
      console.log("Updating offTurnkey charge record");
      updateChargeRecord("offTurnkey");
    }
  }, [
    chargeRecordIds,
    onTurnkeyChargeItems,
    offTurnkeyChargeItems,
    initialOnTurnkeyItems,
    initialOffTurnkeyItems,
    token,
  ]);

  useEffect(() => {
    // Only update if:
    // 1. Charge record IDs exist
    // 2. They are different from initial IDs
    // 3. We're not in the initial load state
    const shouldUpdate = 
      (chargeRecordIds.onTurnkey && 
        initialChargeRecordIds.onTurnkey !== chargeRecordIds.onTurnkey) ||
      (chargeRecordIds.offTurnkey && 
        initialChargeRecordIds.offTurnkey !== chargeRecordIds.offTurnkey);
  
    if (shouldUpdate) {
      if (chargeRecordIds.onTurnkey) {
        handleUpdateChargeRecords('onTurnkey');
      }
      if (chargeRecordIds.offTurnkey) {
        handleUpdateChargeRecords('offTurnkey');
      }
    }
  }, [chargeRecordIds, initialChargeRecordIds, selectedDwr]);
  


  const validRows = useMemo(
    () =>
      rows.filter((row) => {
        const employeeId = row.data.employeeId;
        return employeeId && String(employeeId).trim() !== "";
      }),
    [rows]
  );

  useEffect(() => {
    async function submitEmployeeTimeRecords() {
      if (!timeRecordUpdateNeeded || !timeRecordId || validRows.length === 0)
        return;

      for (const row of validRows) {
        const payload = {
          time_record_id: timeRecordId,
          employee_id: row.data.employeeId,
          start_time: convertTo24Hour(
            row.data.startHour,
            row.data.startMinute,
            row.data.startMeridian
          ),
          end_time: convertTo24Hour(
            row.data.stopHour,
            row.data.stopMinute,
            row.data.stopMeridian
          ),
          rig_time: row.data.rig || 0,
          travel_time: row.data.travel || 0,
          npt_company_time: row.data.nptJmr || 0,
          npt_customer_time: row.data.nptCust || 0,
          role: row.data.role,
        };
        console.log("ETR PAYLOAD: ", payload);

        let url = appUrl + `/jmrapp/employeetimerecords/`;
        let method = "post";
        if (row.data.etrId) {
          url += `${row.data.etrId}/`;
          method = "patch";
        }

        try {
          const response = await axios({
            method,
            url,
            data: payload,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (!row.data.etrId) {
            const newEtrId = response.data.id;
            row.data.etrId = newEtrId;
          }

          console.log(
            `EmployeeTimeRecord ${row.id ? "updated" : "created"} successfully.`
          );
        } catch (error) {
          console.error(
            `Error in EmployeeTimeRecord ${row.id ? "update" : "creation"}:`,
            error
          );
        }
      }

      setTimeRecordUpdateNeeded(false);
    }

    submitEmployeeTimeRecords();
  }, [timeRecordUpdateNeeded, timeRecordId, validRows, token]);

  useEffect(() => {
    const fetchWorkDescriptions = async () => {
      setIsLoadingWorkDescriptions(true);
      try {
        const token = localStorage.getItem("my-jwt");
        const response = await axios.get(
          appUrl + `/jmrapp/workdescriptions/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setWorkDescriptions(response.data || []);
      } catch (error) {
        console.error("There was an error fetching work descriptions:", error);
      } finally {
        setIsLoadingWorkDescriptions(false);
      }
    };
    fetchWorkDescriptions();
  }, []);

  useEffect(() => {
    const sendChargeRecordsUpdate = async () => {
      if (!selectedDwr) {
        console.error("No DWR selected.");
        return;
      }

      const chargeItems =
        updateChargeRecordsTriggered.type === "onTurnkey"
          ? onTurnkeyChargeItems
          : offTurnkeyChargeItems;
      const chargeRecordId = chargeRecordIds[updateChargeRecordsTriggered.type];
      let method = "patch";
      let url = `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`;

      if (!chargeRecordId) {
        method = "post";
        url = `${appUrl}/jmrapp/chargerecords/`;
      }

      const chargeRecordsPayload = {
        dwr: selectedDwr.id,
        service_item_charges: chargeItems
          .filter((item) => item.type === "Service")
          .map((item) => ({
            id: item.chargeId,
            quantity_used: parseFloat(item.quantity_used),
            service_item_id: item.id,
            price_at_use: zeroAllPrices ? 0 : Number(item.price || 0),
          })),
        inventory_item_charges: chargeItems
          .filter((item) => item.type === "Inventory")
          .map((item) => ({
            id: item.chargeId,
            quantity_used: parseFloat(item.quantity_used),
            inventory_item_id: item.id,
            price_at_use: zeroAllPrices ? 0 : Number(item.price || 0),
          })),
      };

      try {
        const response = await axios({
          method,
          url,
          data: chargeRecordsPayload,
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!chargeRecordId) {
            setChargeRecordIds((prevIds) => ({
              ...prevIds,
              [updateChargeRecordsTriggered.type]: response.data.id,
            }));
          }
    

        console.log(
          `${updateChargeRecordsTriggered.type} charge records updated successfully.`
        );
      } catch (error) {
        console.error(
          `Failed to update ${updateChargeRecordsTriggered.type} charge records:`,
          error
        );
      }
    };

    if (updateChargeRecordsTriggered.triggered) {
      sendChargeRecordsUpdate();
      setUpdateChargeRecordsTriggered({ triggered: false, type: null });
    }
  }, [
    updateChargeRecordsTriggered,
    selectedDwr,
    onTurnkeyChargeItems,
    offTurnkeyChargeItems,
    token,
  ]);

  useEffect(() => {
    const fetchRigData = async () => {
      try {
        const token = localStorage.getItem("my-jwt");
        const rigId = well.assigned_rig.id;
        if (rigId) {
          const response = await axios.get(
            appUrl + `/jmrapp/rigs/${rigId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log('RIG DATA: ', response.data)
          setRigData(response.data);
        }
      } catch (error) {
        console.error(
          "There was an error fetching rig data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    // if (formData.wellData?.assigned_rig) {
    fetchRigData();
    // }
  }, []);

  // useEffect(() => {
  //   const fetchInventoryItems = async () => {
  //     try {
  //       const token = localStorage.getItem("my-jwt");
  //       const response = await axios.get(
  //         appUrl + `/jmrapp/active-inventory-items`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //       setInventoryItems(response.data || []);
  //     } catch (error) {
  //       console.error("Failed to fetch inventory items:", error);
  //     }
  //   };

  //   fetchInventoryItems();
  // }, []);

  useEffect(() => {
    const fetchMiscellaneousItems = async () => {
      try {
        const response = await axios.get(
          `${appUrl}/jmrapp/active-miscellaneous-items`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setMiscellaneousItems(response.data || []);
      } catch (error) {
        console.error("Failed to fetch miscellaneous items:", error);
      }
      console.log('MISC ITEMS: ', miscellaneousItems)
    };
  
    fetchMiscellaneousItems();
  }, [token,]);

  // // New function to fetch the current pricebook for the well
  // const fetchCurrentPricebook = useCallback(async () => {
  //   if (!well || !well.id) return;

  //   setIsLoadingPricebook(true);
  //   try {
  //     const response = await axios.get(`${appUrl}/jmrapp/wells/${well.id}/`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     setCurrentPricebook(response.data.inventory_pricebook);
  //   } catch (error) {
  //     console.error("Failed to fetch current pricebook:", error);
  //   } finally {
  //     setIsLoadingPricebook(false);
  //   }
  // }, [well, token]);

  // useEffect(() => {
  //   fetchCurrentPricebook();
  // }, [fetchCurrentPricebook]);


  // useEffect(() => {
  //   const fetchServiceItemsForCustomer = async () => {
  //     const customer_id = well.customer?.id;
  //     if (!customer_id) {
  //       console.log(
  //         "Customer ID is not available. Skipping fetch for service items."
  //       );
  //       return;
  //     }

  //     try {
  //       const token = localStorage.getItem("my-jwt");
  //       const response = await axios.get(
  //         appUrl + `/jmrapp/pricebooks/?customer_id=${customer_id}`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //       setServiceItems(response.data || []);
  //     } catch (error) {
  //       console.error("Failed to fetch service items for customer:", error);
  //     }
  //   };

  //   if (well?.customer.id) {
  //     fetchServiceItemsForCustomer();
  //   }
  // }, [well?.customer.id]);

  const fetchPricebooks = useCallback(async () => {
    if (!well || !well.id) return;
  
    setIsLoadingPricebook(true);
    try {
      const response = await axios.get(`${appUrl}/jmrapp/wells/${well.id}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      const wellData = response.data;
      setCurrentInventoryPricebook(wellData.inventory_pricebook);
      
      if (wellData.service_pricebooks && wellData.service_pricebooks.length > 0) {
        setCurrentServicePricebook(wellData.service_pricebooks);
      } else {
        const customerId = wellData.customer.id;
        
        const customerPricebookResponse = await axios.get(
          `${appUrl}/jmrapp/pricebooks/?customer_id=${customerId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        // Transform the pricebook data to match the expected structure
        const transformedPricebooks = customerPricebookResponse.data.map(item => ({
          id: item.id,
          customer: item.customer,
          service_item: item.service_item,
          price: item.price,
          valid_from: item.valid_from,
          valid_to: item.valid_to,
          locked: item.locked,
          pricing_level_id: null // Add this to match ServicePriceBookSerializer structure
        }));
  
        setCurrentServicePricebook(transformedPricebooks);
      }
    } catch (error) {
      console.error("Failed to fetch pricebooks:", error);
    } finally {
      setIsLoadingPricebook(false);
    }
  }, [well, token, appUrl]);
  
  // Add useEffect to fetch pricebooks
  useEffect(() => {
    fetchPricebooks();
  }, [fetchPricebooks]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem("my-jwt");
        const response = await axios.get(appUrl + `/jmrapp/employee`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployees(response.data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const updatedInventoryObjects = selectedInventoryItems.map((id) => {
      const item = inventoryItems.find((item) => item.id === id);
      return {
        ...item,
        quantity_used: 0,
        original_quantity_available: item.quantity_available,
      };
    });

    setSelectedInventoryObjects(updatedInventoryObjects);

    const updatedServiceObjects = selectedServiceItems
      .map((id) => serviceItems.find((item) => item.id === id))
      .filter((item) => item != null)
      .map((item) => ({ ...item, quantity_used: item.quantity_used || 1 }));

    setSelectedServiceObjects(updatedServiceObjects);
  }, [
    selectedInventoryItems,
    selectedServiceItems,
    inventoryItems,
    serviceItems,
  ]);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const shortDate = selectedDate.replaceAll("-", "").slice(2);
    const rigName = rigData ? rigData.name : "";
    const ticketNumber = `${shortDate}${well.assigned_rig}`;
    setFormData((prevState) => ({
      ...prevState,
      date: selectedDate,
      // ticketNumber,
    }));
  };


  const handleDayChange = (e) => {
    const inputDay = e.target.value;
    setDay(inputDay);
    setFormData((prevState) => ({
      ...prevState,
      day: inputDay,
    }));
  };

  const handleLastDayChange = (value) => {
    setIsLastDay(value);
    setFormData((prevState) => ({
      ...prevState,
      isLastDay: value,
    }));
  };

  const handleContactChange = (e) => {
    const contactId = e.target.value;
    console.log("Selected Contact ID:", contactId);
    setFormData((prevState) => ({
      ...prevState,
      contact_id: contactId ? parseInt(contactId) : null,
    }));
  };

  const handleDWRUpdate = async () => {
    if (!selectedDwr) return;

    try {
      const currentFormData = formData;
      const currentNotes = notes;
      const currentDay = day;
      const currentLastDay = isLastDay;
      // const currentTicketNumber = formData.ticketNumber;


      const payload = {
        date: currentFormData.date,
        day: currentDay,
        is_last_day: currentLastDay,
        notes: currentNotes,
        is_locked: isLocked,
        is_approved: isApproved,
        subproject_type: subprojectType,
        subproject: subprojectId,
        subproject_netsuite_id: subprojectNsId


 
        // ticket_number: currentTicketNumber,  // Include the ticket_number in the payload
      };

      if (currentFormData.contact_id !== null) {
        payload.contact_id = currentFormData.contact_id;
      }

      console.log("Payload for PATCH request:", payload);

      const response = await axios.patch(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Server response:", response.data);

      setSelectedDwr(response.data);
      setFormData((prevState) => ({
        ...prevState,
        contact_id: response.data.contact ? response.data.contact.id : null,
        day: response.data.day,
        ticketNumber: response.data.ticket_number,  // Update the ticketNumber in the form data
        isLastDay: response.data.is_last_day,
        isLocked: response.data.is_locked,
        isApproved: response.data.is_approved,
      }));
      setDay(response.data.day);
      setNotesUpdated(true);
      setIsLastDay(response.data.is_last_day);
      setIsLocked(response.data.isLocked);
      setIsApproved(response.data.isApproved);

      confirmAlert({
        title: "Success",
        message: "DWR details updated successfully.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error(
        "Failed to update DWR details:",
        error.response ? error.response.data : error
      );
      confirmAlert({
        title: "Error",
        message: `Failed to update DWR details: ${
          error.response ? JSON.stringify(error.response.data) : error.message
        }`,
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenChargeModal = (type) => {
    setShowDuplicateChargeError(false);
    setIsChargeModalOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleCloseChargeModal = (type) =>
    setIsChargeModalOpen((prev) => ({ ...prev, [type]: false }));


  const handleChargeRecordSelect = async (
    selectedInventoryIds,
    selectedServiceIds,
    selectedMiscellaneousIds,
    type
  ) => {
    const fetchItemDetails = async (id, itemType) => {
      const response = await axios.get(
        `${appUrl}/jmrapp/${itemType}/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    };
  
    const getPriceFromPricebook = (item, itemType) => {
      if (isLoadingPricebook) return item.price;
  
      if (itemType === 'Inventory') {
        if (!currentInventoryPricebook) return item.price;
        const pricebookItem = currentInventoryPricebook.find(
          pbi => pbi.inventory_item === item.id
        );
        return pricebookItem ? pricebookItem.price : item.price;
      }
  
      if (itemType === 'Service') {
        if (!currentServicePricebook) return item.price;
        const pricebookItem = currentServicePricebook.find(pbi => {
          // Handle both legacy and new pricebook structures
          if (pbi.service_item) {
            // New pricebook structure
            return pbi.service_item.id === item.id;
          } else {
            // Legacy pricebook structure
            return pbi.id === item.id;
          }
        });
        // For legacy pricebooks, the price is directly on the item
        return pricebookItem ? (pricebookItem.service_item ? pricebookItem.price : pricebookItem.price) : item.price;
      }
  
      return item.price;
    };
  
    try {
      const newInventoryItemsDetails = await Promise.all(
        selectedInventoryIds.map((id) => fetchItemDetails(id, "inventoryitems"))
      );
      const newServiceItemsDetails = await Promise.all(
        selectedServiceIds.map((id) => fetchItemDetails(id, "serviceitems"))
      );
      const newMiscellaneousItemsDetails = await Promise.all(
        selectedMiscellaneousIds.map((id) => fetchItemDetails(id, "miscellaneous-item"))
      );
  
      let newChargeItems = [
        ...newInventoryItemsDetails.map((item) => {
          const price = getPriceFromPricebook(item, "Inventory");
          return {
            ...item,
            id: item.id,
            type: "Inventory",
            quantity_used: 1,
            price: price,
            price_at_use: price,
            quantity_available: item.quantity_available,
            netsuite_id: item.ns_eid,
            unit_type: item.unit_type,
            name: item.name,
          };
        }),
        ...newServiceItemsDetails.map((item) => {
          const price = getPriceFromPricebook(item, "Service");
          return {
            ...item,
            id: item.id,
            type: "Service",
            quantity_used: 1,
            price: price,
            price_at_use: price,
            quantity_available: item.quantity_available,
            netsuite_id: item.endpoint_id,
            unit_type: item.unit_type,
            name: item.name,
          };
        }),
        ...newMiscellaneousItemsDetails.map((item) => ({
          ...item,
          id: item.id,
          type: "Miscellaneous",
          quantity_used: 1,
          price: item.price,
          price_at_use: item.price,
          customName: item.name,
          customPrice: item.price,
          endpoint_id: item.endpoint_id,
        })),
      ];
  
      const existingChargeItems =
        type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
  
      newChargeItems = newChargeItems.filter(
        (newItem) =>
          !existingChargeItems.some(
            (existingItem) =>
              existingItem.id === newItem.id &&
              existingItem.type === newItem.type
          )
      );
  
      if (type === "onTurnkey") {
        setOnTurnkeyChargeItems((prevItems) => [
          ...prevItems,
          ...newChargeItems,
        ]);
      } else {
        setOffTurnkeyChargeItems((prevItems) => [
          ...prevItems,
          ...newChargeItems,
        ]);
      }
  
      setIsChargeModalOpen((prev) => ({ ...prev, [type]: false }));
    } catch (error) {
      console.error("Error fetching item details:", error);
      confirmAlert({
        title: "Error",
        message: "Failed to add charge items. Please try again.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
    }
  };
  // const handleChargeRecordSelect = async (
  //   selectedInventoryIds,
  //   selectedServiceIds,
  //   selectedMiscellaneousIds,
  //   type
  // ) => {
  //   const fetchItemDetails = async (id, itemType) => {
  //     const response = await axios.get(
  //       `${appUrl}/jmrapp/${itemType}/${id}`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     return response.data;
  //   };
  
  //   const getPriceFromPricebook = (item, itemType) => {
  //     if (!currentPricebook || isLoadingPricebook) return item.price;
  
  //     const pricebookItem = currentPricebook.find(
  //       pbi => (itemType === 'Inventory' ? pbi.inventory_item : pbi.service_item) === item.id
  //     );
  
  //     return pricebookItem ? pricebookItem.price : item.price;
  //   };
  
  //   try {
  //     const newInventoryItemsDetails = await Promise.all(
  //       selectedInventoryIds.map((id) => fetchItemDetails(id, "inventoryitems"))
  //     );
  //     const newServiceItemsDetails = await Promise.all(
  //       selectedServiceIds.map((id) => fetchItemDetails(id, "serviceitems"))
  //     );
  //     const newMiscellaneousItemsDetails = await Promise.all(
  //       selectedMiscellaneousIds.map((id) => fetchItemDetails(id, "miscellaneous-item"))
  //     );

  //     let newChargeItems = [
  //       ...newInventoryItemsDetails.map((item) => {
  //         const price = getPriceFromPricebook(item, "Inventory");
  //         return {
  //           ...item,
  //           id: item.id,
  //           type: "Inventory",
  //           quantity_used: 1,
  //           price: price,
  //           price_at_use: price, // Add this field
  //           quantity_available: item.quantity_available,
  //           netsuite_id: item.ns_eid,
  //           unit_type: item.unit_type,
  //           name: item.name,
  //         };
  //       }),
  //       ...newServiceItemsDetails.map((item) => {
  //         const price = getPriceFromPricebook(item, "Service");
  //         return {
  //           ...item,
  //           id: item.id,
  //           type: "Service",
  //           quantity_used: 1,
  //           price: price,
  //           price_at_use: price, // Add this field
  //           quantity_available: item.quantity_available,
  //           netsuite_id: item.endpoint_id,
  //           unit_type: item.unit_type,
  //           name: item.name,
  //         };
  //       }),
  //       ...newMiscellaneousItemsDetails.map((item) => ({
  //         ...item,
  //         id: item.id,
  //         type: "Miscellaneous",
  //         quantity_used: 1,
  //         price: item.price,
  //         price_at_use: item.price, // Add this field
  //         customName: item.name,
  //         customPrice: item.price,
  //         endpoint_id: item.endpoint_id,
  //       })),
  //     ];
  
  //     const existingChargeItems =
  //       type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
  
  //     newChargeItems = newChargeItems.filter(
  //       (newItem) =>
  //         !existingChargeItems.some(
  //           (existingItem) =>
  //             existingItem.id === newItem.id &&
  //             existingItem.type === newItem.type
  //         )
  //     );
  
  //     if (type === "onTurnkey") {
  //       setOnTurnkeyChargeItems((prevItems) => [
  //         ...prevItems,
  //         ...newChargeItems,
  //       ]);
  //     } else {
  //       setOffTurnkeyChargeItems((prevItems) => [
  //         ...prevItems,
  //         ...newChargeItems,
  //       ]);
  //     }
  
  //     setIsChargeModalOpen((prev) => ({ ...prev, [type]: false }));
  //   } catch (error) {
  //     console.error("Error fetching item details:", error);
  //     confirmAlert({
  //       title: "Error",
  //       message: "Failed to add charge items. Please try again.",
  //       buttons: [{ label: "OK", onClick: () => {} }],
  //     });
  //   }
  // };

  // const handleSelectWorkDescriptions = (selectedDescriptions) => {
  //   // Filter out descriptions already in workAssignments
  //   const uniqueDescriptions = selectedDescriptions.filter(
  //     (desc) =>
  //       !workAssignments.some(
  //         (assignment) => assignment.workDescriptionId === desc.id
  //       )
  //   );

  //   // Convert to work assignments format and append to existing assignments
  //   const newAssignments = uniqueDescriptions.map((desc) => ({
  //     workDescriptionId: desc.id,
  //     workDescriptionName: desc.description,
  //     fromTime: "", // Initialize with default values
  //     toTime: "", // Initialize with default values
  //   }));

  //   setWorkAssignments((prevAssignments) => [
  //     ...prevAssignments,
  //     ...newAssignments,
  //   ]);
  //   handleCloseModal();
  // };

  // REPLACE handleSelectWorkDescriptions
// Replace handleSelectWorkDescriptions with this updated version
const handleSelectWorkDescriptions = (selectedDescriptions) => {
  if (!Array.isArray(selectedDescriptions) || selectedDescriptions.length === 0) {
    return;
  }

  const newAssignments = selectedDescriptions.map(desc => ({
    workDescriptionId: desc.id,
    work_description: desc,
    description: desc.description || '',
    fromTime: '06:00',  // Default start time
    toTime: '06:30',    // Default end time
    input_values: {},   // Initialize empty input values
    is_legacy: !desc.template_text // Mark as legacy if no template
  }));

  setWorkAssignments(prevAssignments => {
    const updatedAssignments = [...prevAssignments, ...newAssignments]
      .sort((a, b) => {
        // Sort by fromTime if available
        const timeA = a.fromTime || '';
        const timeB = b.fromTime || '';
        return timeA.localeCompare(timeB);
      });
    return updatedAssignments;
  });

  handleCloseModal();
};

// Add to your form submission handler
const handleSubmitClick = () => {
  if (!selectedDwr && !formData.date) {
    confirmAlert({
      title: "Error",
      message: "Please enter a date before submitting the DWR.",
      buttons: [{ label: "OK", onClick: () => {} }],
    });
    return;
  }

  setIsSubmitting(true);
  setIsSubmitClicked(true);
  setTimeRecordUpdateNeeded(true);
};

  const handleDeleteAssignmentFromDWRForm = async (assignmentId) => {
    try {
      await axios.delete(
        appUrl + `/jmrapp/workassignments/${assignmentId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setWorkAssignments((currentAssignments) =>
        currentAssignments.filter(
          (assignment) => assignment.id !== assignmentId
        )
      );
    } catch (error) {
      confirmAlert({
        title: "Error",
        message: "Failed to delete work assignment. Please try again.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
    }
  };

  // UPDATE handleUpdateWorkAssignments
const handleUpdateWorkAssignments = async () => {
  if (!selectedDwr) return;

  try {
    setIsSubmitting(true);

    const updates = workAssignments
      .filter(assignment => assignment.id)
      .map(async assignment => {
        const payload = {
          description: assignment.description,
          from_time: assignment.fromTime,
          to_time: assignment.toTime,
          work_description_id: assignment.workDescriptionId,
          input_values: assignment.input_values,
          is_legacy: assignment.is_legacy,
          dwr: selectedDwr.id
        };

        return axios.patch(
          `${appUrl}/jmrapp/workassignments/${assignment.id}/`,
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      });

    const creations = workAssignments
      .filter(assignment => !assignment.id)
      .map(async assignment => {
        const payload = {
          description: assignment.description,
          from_time: assignment.fromTime,
          to_time: assignment.toTime,
          work_description_id: assignment.workDescriptionId,
          input_values: assignment.input_values,
          is_legacy: assignment.is_legacy,
          dwr: selectedDwr.id
        };

        return axios.post(
          `${appUrl}/jmrapp/workassignments/`,
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      });

    const responses = await Promise.all([...updates, ...creations]);
    
    const updatedAssignments = responses.map(response => ({
      ...response.data,
      id: response.data.id,
      work_description: response.data.work_description,
      workDescriptionId: response.data.work_description?.id,
      fromTime: response.data.from_time,
      toTime: response.data.to_time,
      input_values: response.data.input_values || {},
      is_legacy: response.data.is_legacy
    }));

    setWorkAssignments(updatedAssignments);
    setCurrentWorkAssignments(updatedAssignments);

    confirmAlert({
      title: "Success",
      message: "Work assignments updated successfully.",
      buttons: [{ label: "OK", onClick: () => {} }],
    });
  } catch (error) {
    confirmAlert({
      title: "Error",
      message: "Failed to update work assignments. Please try again.",
      buttons: [{ label: "OK", onClick: () => {} }],
    });
  } finally {
    setIsSubmitting(false);
  }
};
  // const handleUpdateWorkAssignments = async () => {
  //   const updates = workAssignments
  //     .filter((assignment) => assignment.id)
  //     .map(async (assignment) => {
  //       const { id, description, fromTime, toTime } = assignment;
  //       const payload = {
  //         description,
  //         from_time: fromTime,
  //         to_time: toTime,
  //       };
  //       return axios.patch(
  //         appUrl + `/jmrapp/workassignments/${id}/`,
  //         payload,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //     });

  //   const creations = workAssignments
  //     .filter((assignment) => !assignment.id)
  //     .map(async (assignment) => {
  //       const { description, fromTime, toTime } = assignment;
  //       const payload = {
  //         description,
  //         from_time: fromTime,
  //         to_time: toTime,
  //         dwr: selectedDwr.id,
  //       };
  //       console.log("CREATION PAYLOAD: ", payload);

  //       return axios.post(appUrl + `/jmrapp/workassignments/`, payload, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  //     });

  //   try {
  //     const responses = await Promise.all([...updates, ...creations]);
  //     const createdAssignments = responses
  //       .slice(updates.length)
  //       .map((response) => response.data);

  //     setWorkAssignments((prevAssignments) => [
  //       ...prevAssignments.filter((assignment) => assignment.id),
  //       ...createdAssignments,
  //     ]);

  //     console.log("Work assignments processed successfully.");

  //     confirmAlert({
  //       title: "Success",
  //       message: "Work assignments updated successfully.",
  //       buttons: [
  //         {
  //           label: "OK",
  //           onClick: () => {},
  //         },
  //       ],
  //     });
  //   } catch (error) {
  //     console.error(
  //       "An error occurred while processing work assignments:",
  //       error
  //     );
  //   }
  // };



  // Replace the entire handleUpdateChargeRecords function
  // const handleUpdateChargeRecords = useCallback(async () => {
  //   if (!selectedDwr) {
  //     console.error("No DWR selected. Cannot update charge records.");
  //     return;
  //   }

  //   console.log(`Updating charge records for DWR ${selectedDwr.id}`);

  //   const MAX_RETRIES = 3;
  //   const RETRY_DELAY = 1000;

  //   const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  //   const updateChargeRecord = async (items, type, retryCount = 0) => {
  //     const chargeRecordId = chargeRecordIds[type];
  //     if (!chargeRecordId) {
  //       console.log(`No charge record ID found for ${type}, skipping update`);
  //       return;
  //     }

  //     const initialItems = type === "onTurnkey" ? initialOnTurnkeyItems : initialOffTurnkeyItems;
  //     const hasChanges = JSON.stringify(items) !== JSON.stringify(initialItems);

  //     if (!hasChanges) {
  //       console.log(`No changes detected for ${type} charge records. Skipping update.`);
  //       return;
  //     }

  //     try {
  //       const sortedItems = [...items].sort((a, b) => a.id - b.id);

  //       const totalAmount = sortedItems.reduce((sum, item) => {
  //         const price = zeroAllPrices ? 0 : parseFloat(item.price || 0);
  //         const quantity = parseFloat(item.quantity_used || 0);
  //         return sum + (price * quantity);
  //       }, 0);

  //       const payload = {
  //         total_amount: Number(totalAmount.toFixed(2)),
  //         service_item_charges: sortedItems
  //           .filter((item) => item.type === "Service")
  //           .sort((a, b) => a.id - b.id)
  //           .map((item) => ({
  //             id: item.chargeId,
  //             service_item_id: item.id,
  //             quantity_used: Number(item.quantity_used),
  //             price_at_use: zeroAllPrices ? 0 : Number(item.price || 0)
  //           })),
  //         inventory_item_charges: sortedItems
  //           .filter((item) => item.type === "Inventory")
  //           .sort((a, b) => a.id - b.id)
  //           .map((item) => ({
  //             id: item.chargeId,
  //             inventory_item_id: item.id,
  //             quantity_used: Number(item.quantity_used),
  //             price_at_use: zeroAllPrices ? 0 : Number(item.price || 0)
  //           })),
  //         miscellaneous_item_charges: sortedItems
  //           .filter((item) => item.type === "Miscellaneous")
  //           .sort((a, b) => a.id - b.id)
  //           .map((item) => ({
  //             id: item.chargeId,
  //             miscellaneous_item_id: item.id,
  //             quantity_used: Number(item.quantity_used),
  //             custom_name: item.name || item.custom_name,
  //             price_at_use: Number(item.price || item.price_at_use)
  //           }))
  //       };

  //       const headers = {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         'X-Transaction-Type': type
  //       };

  //       const response = await axios.patch(
  //         `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
  //         payload,
  //         { headers }
  //       );

  //       console.log(`${type} charge records updated successfully:`, response.data);

  //       if (type === "onTurnkey") {
  //         setInitialOnTurnkeyItems(sortedItems);
  //       } else {
  //         setInitialOffTurnkeyItems(sortedItems);
  //       }

  //     } catch (error) {
  //       if (error.response?.data?.detail?.includes('deadlock detected') && retryCount < MAX_RETRIES) {
  //         console.log(`Deadlock detected for ${type}, attempt ${retryCount + 1}/${MAX_RETRIES}. Retrying...`);
  //         await delay(RETRY_DELAY * (retryCount + 1));
  //         return updateChargeRecord(items, type, retryCount + 1);
  //       }
  //       console.error(`Error updating ${type} charge records:`, error.response?.data || error.message);
  //       throw error;
  //     }
  //   };

  //   try {
  //     await updateChargeRecord(onTurnkeyChargeItems, "onTurnkey");
  //     await updateChargeRecord(offTurnkeyChargeItems, "offTurnkey");
  //   } catch (error) {
  //     console.error('Failed to update charge records:', error);
  //     confirmAlert({
  //       title: "Error",
  //       message: "Failed to update charge records. Please try again.",
  //       buttons: [{ label: "OK", onClick: () => {} }],
  //     });
  //   }
  // }, [selectedDwr, chargeRecordIds, onTurnkeyChargeItems, offTurnkeyChargeItems, 
  //     initialOnTurnkeyItems, initialOffTurnkeyItems, token, zeroAllPrices]);

  const handleUpdateChargeRecords = useCallback(async () => {
    if (!selectedDwr) {
      return;
    }
  
    const MAX_RETRIES = 5;
    const BASE_DELAY = 1000;
  
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  
    const updateChargeRecord = async (items, type) => {
      const chargeRecordId = chargeRecordIds[type];
      if (!chargeRecordId) {
        return false;
      }
    
      const initialItems = type === "onTurnkey" ? initialOnTurnkeyItems : initialOffTurnkeyItems;
      const hasChanges = JSON.stringify(items) !== JSON.stringify(initialItems);
    
      if (!hasChanges) {
        return false;
      }
    
      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        try {
          const sortedItems = [...items].sort((a, b) => a.id - b.id);
    
          const totalAmount = sortedItems.reduce((sum, item) => {
            const price = zeroAllPrices ? 0 : parseFloat(item.price_at_use || item.price || 0);
            const quantity = parseFloat(item.quantity_used || 0);
            return sum + (price * quantity);
          }, 0);
    
          const payload = {
            total_amount: Number(totalAmount.toFixed(2)),
            service_item_charges: sortedItems
              .filter((item) => item.type === "Service")
              .sort((a, b) => a.id - b.id)
              .map((item) => ({
                id: item.chargeId,
                service_item_id: item.id,
                quantity_used: Number(item.quantity_used),
                price_at_use: zeroAllPrices ? 0 : Number(item.price_at_use || item.price || 0)
              })),
            inventory_item_charges: sortedItems
              .filter((item) => item.type === "Inventory")
              .sort((a, b) => a.id - b.id)
              .map((item) => ({
                id: item.chargeId,
                inventory_item_id: item.id,
                quantity_used: Number(item.quantity_used),
                price_at_use: zeroAllPrices ? 0 : Number(item.price_at_use || item.price || 0)
              })),
            miscellaneous_item_charges: sortedItems
              .filter((item) => item.type === "Miscellaneous")
              .sort((a, b) => a.id - b.id)
              .map((item) => ({
                id: item.chargeId,
                miscellaneous_item_id: item.id,
                quantity_used: Number(item.quantity_used),
                custom_name: item.name || item.custom_name,
                price_at_use: Number(item.price || item.price_at_use)
              }))
          };
    
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Transaction-Type': type
          };
    
          const response = await axios.patch(
            `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
            payload,
            { headers }
          );
    
          if (type === "onTurnkey") {
            setInitialOnTurnkeyItems(sortedItems);
          } else {
            setInitialOffTurnkeyItems(sortedItems);
          }
    
          return true; // Indicate that we actually updated something
    
        } catch (error) {
          const isDeadlockError = error.response?.status === 500 && 
            (error.response.data.includes('deadlock detected') || 
             error.response.data.includes('could not serialize access'));
    
          if (isDeadlockError && attempt < MAX_RETRIES - 1) {
            const delayTime = BASE_DELAY * Math.pow(2, attempt);
            await delay(delayTime);
          } else {
            throw error;
          }
        }
      }
    
      throw new Error(`Failed to update ${type} charge record after ${MAX_RETRIES} attempts`);
    };
    
  
    try {
      const [onTurnkeyUpdated, offTurnkeyUpdated] = await Promise.all([
        updateChargeRecord(onTurnkeyChargeItems, "onTurnkey"),
        updateChargeRecord(offTurnkeyChargeItems, "offTurnkey")
      ]);
  
      if (onTurnkeyUpdated || offTurnkeyUpdated) {
        confirmAlert({
          title: "Success",
          message: "Charge records updated successfully.",
          buttons: [{ label: "OK", onClick: () => {} }],
        });
      }
  
    } catch (error) {
      confirmAlert({
        title: "Error",
        message: "Failed to update charge records. Please try again later.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
    }
  }, [
    selectedDwr, 
    chargeRecordIds, 
    onTurnkeyChargeItems, 
    offTurnkeyChargeItems, 
    initialOnTurnkeyItems, 
    initialOffTurnkeyItems, 
    token, 
    zeroAllPrices
  ]);

useEffect(() => {
  if (updateChargeRecordsTriggered.triggered) {
    handleUpdateChargeRecords();
    setUpdateChargeRecordsTriggered({ triggered: false, type: null });
  }
}, [updateChargeRecordsTriggered, handleUpdateChargeRecords]);
  
const onUpdateChargeRecordsTrigger = useCallback(() => {
  setUpdateChargeRecordsTriggered({ triggered: true, type: null });
}, []);

  const handleNotesUpdate = async () => {
    if (!selectedDwr) return;

    try {
      await axios.patch(
        appUrl + `/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        { notes: notes },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotesUpdated(true);

      confirmAlert({
        title: "Success",
        message: "Notes updated successfully.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error("Failed to update notes:", error);
    }
  };

  const handleUnlock = async () => {
    try {
      const response = await axios.post(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/unlock/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLocked(false);
      // Update the selectedDwr state with the new unlocked status
      setSelectedDwr({ ...selectedDwr, is_locked: false });
  
      // Send notification email after unlocking
      // await axios.post(
      //   `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/send_unlock_notification/`,
      //   {},
      //   { headers: { Authorization: `Bearer ${token}` } }
      // );
    } catch (error) {
      console.error('Failed to unlock DWR:', error);
    }
  };
  

  const handleManualLock = async () => {
    try {
      const response = await axios.post(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/manual_lock/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLocked(true);
      // Update the selectedDwr state with the new locked status
      setSelectedDwr({ ...selectedDwr, is_locked: true });
    } catch (error) {
      console.error('Failed to lock DWR:', error);
    }
  };

  const handleLockChange = async (event) => {
    const newLockedState = event.target.checked;
    if (selectedDwr.well.status === 'Closed' && isLocked) {
      confirmAlert({
        title: "Well Closed",
        message: "This well has been locked. To unlock and modify this DWR, you must contact an Admin to reopen the well.",
        buttons: [{ label: "OK" }],
      });
      return;
    }
    try {
      if (newLockedState) {
        await handleManualLock();
      } else {
        await handleUnlock();
      }
      setIsLocked(newLockedState);
    } catch (error) {
      console.error('Failed to change lock state:', error);
      // Optionally show an error message to the user
    }
  };

  const handleApprovalChange = async (event) => {
    const newApprovalState = event.target.checked;
    try {
      console.log("Attempting to update DWR approval status");
      const response = await axios.patch(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        { is_approved: newApprovalState },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("DWR approval status updated successfully");
      setIsApproved(newApprovalState);
      setSelectedDwr({ ...selectedDwr, is_approved: newApprovalState });

      confirmAlert({
        title: "Success",
        message: `DWR ${newApprovalState ? 'approved' : 'unapproved'} successfully.`,
        buttons: [{ label: "OK" }],
      });
    } catch (error) {
      console.error('Failed to change approval state:', error.response?.data || error);
      confirmAlert({
        title: "Error",
        message: "Failed to change approval status. Only district managers can approve DWRs.",
        buttons: [{ label: "OK" }],
      });
    }
  };
  


  const handleNavigate = async (direction) => {
    if (isNavigating) return;
    
    setIsNavigating(true);
    const cleanupRef = { current: false };
    
    const adjacentDWR = getAdjacentDWR(selectedDwr, direction);
    if (!adjacentDWR) {
      setIsNavigating(false);
      return;
    }
  
    try {
      const fullDwrData = await fetchFullDWRData(adjacentDWR.id);
      
      if (cleanupRef.current) return;
  
      // Reset all charge-related states first
      setOnTurnkeyChargeItems([]);
      setOffTurnkeyChargeItems([]);
      setInitialOnTurnkeyItems([]);
      setInitialOffTurnkeyItems([]);
      setHasLoadedCharges(false);
      setUpdateChargeRecordsTriggered({ triggered: false, type: null });
      setChargeRecordIds({ onTurnkey: null, offTurnkey: null });
  
      // Then update DWR and form data
      setSelectedDwr(fullDwrData);
      setFormData({
        id: fullDwrData.id,
        date: fullDwrData.date,
        day: fullDwrData.day,
        isLastDay: fullDwrData.is_last_day,
        ticketNumber: fullDwrData.ticket_number,
        is_locked: fullDwrData.is_locked,
        is_approved: fullDwrData.is_approved,
        notes: fullDwrData.notes || "",
        contact_id: fullDwrData.contact?.id || null,
        chargeRecords: {
          onTurnkey: fullDwrData.charge_records?.filter(record => record.on_turnkey) || [],
          offTurnkey: fullDwrData.charge_records?.filter(record => !record.on_turnkey) || []
        }
      });
  
      // Update all other state atomically
      if (!cleanupRef.current) {
        setDay(fullDwrData.day);
        setNotes(fullDwrData.notes || "");
        setIsLastDay(fullDwrData.is_last_day);
        setIsLocked(fullDwrData.is_locked);
        setIsApproved(fullDwrData.is_approved);
        setNotesUpdated(false);
        if (fullDwrData.time_record) {
          setTimeRecordId(fullDwrData.time_record.id);
          setEmployeeTimeRecords(fullDwrData.time_record.employee_time_records || []);
        } else {
          setTimeRecordId(null);
          setEmployeeTimeRecords([]);
        }
  
        // Reset work assignments
        setWorkAssignments(
          fullDwrData.work_assignments
            .map(assignment => ({
              id: assignment.id,
              work_description: assignment.work_description,
              workDescriptionId: assignment.work_description?.id,
              description: assignment.description,
              fromTime: assignment.fromTime || assignment.from_time || '00:00', // Check both formats
              toTime: assignment.toTime || assignment.to_time || '00:00',       // Check both formats
              input_values: assignment.input_values || {},
              is_legacy: assignment.is_legacy || !assignment.work_description?.template_text
            }))
            .sort((a, b) => {
              const timeA = a.fromTime || '';
              const timeB = b.fromTime || '';
              return timeA.localeCompare(timeB);
            })
        );
      }
  
    } catch (error) {
      console.error('Failed to fetch full DWR data:', error);
      if (!cleanupRef.current) {
        confirmAlert({
          title: "Error",
          message: "Failed to load DWR data. Please try again.",
          buttons: [{ label: "OK" }],
        });
      }
    } finally {
      if (!cleanupRef.current) {
        setIsNavigating(false);
      }
    }
  
    return () => {
      cleanupRef.current = true;
    };
  };
  

  // Add this new function after the state declarations
  const organizeEmployeesByRig = useMemo(() => {
    if (!rigData || !employees.length) return { rigEmployees: [], otherEmployees: [] };

    // Convert rig name to department format (e.g., "Rig 205" to "RIG205")
    const rigDepartment = rigData.name.replace(/\s+/g, '').toUpperCase();

    // Filter out employees who have an endpoint_id that is not null
    const activeEmployees = employees.filter(emp => emp.endpoint_id === null);

    return {
      rigEmployees: activeEmployees.filter(emp => emp.department === rigDepartment),
      otherEmployees: activeEmployees.filter(emp => emp.department !== rigDepartment)
    };
  }, [rigData, employees]);

    const handleSendToADP = async () => {
    if (!selectedDwr?.time_record) {
      confirmAlert({
        title: "Error",
        message: "No time records found to send to ADP.",
        buttons: [{ label: "OK" }],
      });
      return;
    }

    try {
      console.log("Starting ADP sync process");
      const timeRecordResponse = await axios.get(
        `${appUrl}/jmrapp/timerecord/${selectedDwr.time_record.id}/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const employeeTimeRecords = timeRecordResponse.data.employee_time_records;
      console.log("Employee time records retrieved:", employeeTimeRecords);
      const dwrDate = new Date(selectedDwr.date);
      
      // Track employees missing ADP IDs and failed entries
      let missingAdpIdCount = 0;
      let failedEntries = [];

      // For each employee time record, create an ADP timecard entry
      for (const etr of employeeTimeRecords) {
        console.log("Processing employee time record:", etr);
        
        if (!etr.employee.adp_associate_oid || !etr.employee.adp_work_assignment_id) {
          console.warn(
            `Employee ${etr.employee.first_name} ${etr.employee.last_name} missing ADP IDs:`,
            `\nAssociate OID: ${etr.employee.adp_associate_oid}`,
            `\nWork Assignment ID: ${etr.employee.adp_work_assignment_id}`
          );
          missingAdpIdCount++;
          continue;
        }

        // Convert time strings to datetime objects
        console.log("Converting time strings to datetime objects");
        const [startHours, startMinutes] = etr.start_time.split(':');
        const [endHours, endMinutes] = etr.end_time.split(':');
        
        // Create a UTC date object for the DWR date at midnight and add one day
        const dwrDateUTC = new Date(Date.UTC(
          dwrDate.getFullYear(),
          dwrDate.getMonth(),
          dwrDate.getDate() + 1  // Add one day to the DWR date
        ));

        // Function to create an ISO string with the correct time while preserving the DWR date
        const createTimeString = (hours, minutes, baseDate) => {
          // Ensure hours is interpreted as 24-hour format
          let hour24 = parseInt(hours);
          
          // Create a new date object with the time
          const date = new Date(baseDate);
          date.setUTCHours(hour24, parseInt(minutes), 0);
          
          // Format as ISO string but keep only up to seconds
          return date.toISOString().slice(0, 19) + 'Z';
        };

        // Create the time strings
        const startTimeString = createTimeString(startHours, startMinutes, dwrDateUTC);
        const endTimeString = createTimeString(endHours, endMinutes, dwrDateUTC);

        console.log("Sending timecard creation request to ADP:", {
          associate_oid: etr.employee.adp_associate_oid,
          work_assignment_id: etr.employee.adp_work_assignment_id,
          start_time: startTimeString,
          end_time: endTimeString,
          dwr_id: selectedDwr.id
        });

        // Create ADP timecard entry
        try {
          const adpResponse = await axios.post(
            `${appUrl}/jmrapp/adp/create-timecard/`,
            {
              associate_oid: etr.employee.adp_associate_oid,
              work_assignment_id: etr.employee.adp_work_assignment_id,
              start_time: startTimeString,
              end_time: endTimeString,
              dwr_id: selectedDwr.id
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          console.log("ADP timecard creation response:", adpResponse.data);

          // Check for failure status in the response body
          const confirmMessage = adpResponse.data?.confirmMessage;
          if (confirmMessage?.requestStatusCode?.codeValue === 'failed') {
            const errorMessages = confirmMessage.processMessages
              ?.filter(msg => msg.messageTypeCode?.codeValue === 'error')
              ?.map(msg => msg.userMessage?.messageTxt || 'Unknown error')
              || ['Unknown error'];

            throw new Error(errorMessages.join('; '));
          }
        } catch (adpError) {
          console.error("Error creating ADP timecard:", adpError.response?.data || adpError);
          failedEntries.push({
            employee: `${etr.employee.first_name} ${etr.employee.last_name}`,
            error: adpError.response?.data?.confirmMessage?.processMessages?.[0]?.userMessage?.messageTxt || 
                  adpError.response?.data?.error || 
                  adpError.message
          });
        }
      }

      // Show appropriate success/error message
      if (failedEntries.length > 0) {
        const errorMessage = `Some time records failed to send to ADP:\n\n${failedEntries.map(entry => 
          `${entry.employee}: ${entry.error}`
        ).join('\n')}\n\n${missingAdpIdCount > 0 ? `Additionally, ${missingAdpIdCount} employee(s) were skipped due to missing ADP IDs.` : ''}`;
        
        confirmAlert({
          title: "Partial Success",
          message: errorMessage,
          buttons: [{ label: "OK" }],
        });
      } else {
        confirmAlert({
          title: "Success",
          message: `Time records sent to ADP successfully for well in ${selectedDwr.well.area}.${missingAdpIdCount > 0 ? ` Note: ${missingAdpIdCount} employee(s) were skipped due to missing ADP IDs.` : ''}`,
          buttons: [{ label: "OK" }],
        });
      }
    } catch (error) {
      console.error('Failed to sync time records to ADP:', error.response?.data || error);
      confirmAlert({
        title: "Error",
        message: "Failed to send time records to ADP. Please try again or contact support.",
        buttons: [{ label: "OK" }],
      });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
      {selectedDwr ? (
                <DWRNavigationHeader 
          selectedDwr={selectedDwr}
          formData={formData}
          handleNavigate={handleNavigate}
          isNavigating={isNavigating}
          getAdjacentDWR={getAdjacentDWR}
        />
      ) : (
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
          New DWR
        </Typography>
      )}
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Date"
              type="date"
              value={formData.date}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Day"
              type="text"
              value={day}
              onChange={handleDayChange}
              placeholder="Enter day"
              fullWidth
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1">
              Ticket Number: {formData.ticketNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth disabled={isLocked}>
              <InputLabel id="contact-label">Contact</InputLabel>
              <Select
                labelId="contact-label"
                id="contact"
                value={formData.contact_id || ""}
                onChange={handleContactChange}
                label="Contact"
              >
                <MenuItem value="">
                  <em>Select a contact</em>
                </MenuItem>
                {contacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.first_name} {contact.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch 
                  checked={isLastDay}
                  onChange={(e) => handleLastDayChange(e.target.checked)}
                    color="primary"
                    disabled={isLocked}
                  />
                }
                label="Is Last Day"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLocked}
                  onChange={handleLockChange}
                  icon={<LockOpenIcon />}
                  checkedIcon={<LockIcon />}
                  disabled={!selectedDwr || !currentUser || (!hasPermission('jmrapp.can_lock_dwr') && !hasPermission('jmrapp.can_unlock_dwr'))}
                />
              }
              label={isLocked ? "Locked" : "Unlocked"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isApproved}
                  onChange={handleApprovalChange}
                  icon={<ThumbUpOffAltIcon />}
                  checkedIcon={<ThumbUpIcon />}
                  disabled={!selectedDwr ||
                     !currentUser 
                     || (!currentUser.is_district_manager && !currentUser.is_superuser)
                        || isLocked
                      }
                />
              }
              label={isApproved ? "Approved" : "Not Approved"}
            />
          </Grid>

        </Grid>
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <WellForm well={well} disabled={true} />
      </Box>
      <div>
      <h3>Work Assignments</h3>
    {isLoadingWorkDescriptions ? (
      <p>Loading work descriptions...</p>
    ) : (
      <>
      <Button 
      variant="contained"
      onClick={handleOpenModal}
      disabled={isLocked || isDwrLoading}
      sx={{ 
        mb: 2,
        backgroundColor: '#0b6e4f',
        '&:hover': {
          backgroundColor: '#095c42'
        }
      }}
      >
      Choose Work Descriptions
      </Button>
        
        {showDuplicateChargeError && (
          <Typography color="error" sx={{ mb: 2 }}>
            Duplicate work descriptions are not allowed
          </Typography>
        )}

        <WorkAssignment
          workAssignments={workAssignments}
          setWorkAssignments={setWorkAssignments}
          workDescriptions={workDescriptions}
          onDeleteAssignment={handleDeleteAssignmentFromDWRForm}
          disabled={isLocked}
        />

        <WorkDescriptionsModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          workDescriptions={workDescriptions}
          setSelectedWorkDescriptions={handleSelectWorkDescriptions}
          disabled={isLocked}
          existingDescriptions={workAssignments}
        />

        {selectedDwr && (
          <Button
            variant="contained"
            onClick={handleUpdateWorkAssignments}
            disabled={isLocked || isSubmitting || 
              JSON.stringify(workAssignments) === JSON.stringify(currentWorkAssignments)}
              sx={{ 
                mb: 2,
                backgroundColor: '#0b6e4f',
                '&:hover': {
                  backgroundColor: '#095c42'
                }
              }}
                       >
            Update Work Assignments
            {isSubmitting && '...'}
          </Button>
        )}
      </>
    )}
  </div>

        {/* <h3>Work Assignments</h3>
        {isLoadingWorkDescriptions ? (
          <p>Loading work descriptions...</p>
        ) : (
          <WorkAssignment
            workAssignments={workAssignments}
            setWorkAssignments={setWorkAssignments}
            workDescriptions={workDescriptions}
            onDeleteAssignment={handleDeleteAssignmentFromDWRForm}
            disabled={isLocked}
          />
        )}
      </div>
      <div>
        {isModalOpen && (
          <WorkDescriptionsModal
            workDescriptions={workDescriptions}
            setSelectedWorkDescriptions={handleSelectWorkDescriptions}
            handleClose={handleCloseModal}
            disabled={isLocked}
          />
        )}
        {selectedDwr && (
          <button className="btn hover" onClick={handleUpdateWorkAssignments} disabled={isLocked}>
            Update Work Assignments
          </button>
        )}
      </div> */}
      <h3>Charge Record</h3>
      <button
        className="btn hover"
        onClick={() => handleOpenChargeModal("onTurnkey")}
        disabled={isDwrLoading || areChargesLoading || isLocked}
      >
        {isDwrLoading || areChargesLoading
          ? "Loading..."
          : "Choose On-Turnkey Charges"}
      </button>
      <button
        className="btn hover"
        onClick={() => handleOpenChargeModal("offTurnkey")}
        disabled={isDwrLoading || areChargesLoading || isLocked}
      >
        {isDwrLoading || areChargesLoading
          ? "Loading..."
          : "Choose Off-Turnkey Charges"}
      </button>
      {isChargeModalOpen.onTurnkey && !isDwrLoading && !areChargesLoading && (
        <ChargeRecordModal
          open={isChargeModalOpen.onTurnkey}
          handleClose={() => handleCloseChargeModal("onTurnkey")}
          inventoryItems={inventoryItems}
          serviceItems={serviceItems}
          miscellaneousItems={miscellaneousItems}
          onChargeRecordSelect={(selectedInventoryIds, selectedServiceIds, selectedMiscellaneousIds) =>
            handleChargeRecordSelect(
              selectedInventoryIds,
              selectedServiceIds,
              selectedMiscellaneousIds,
              "onTurnkey"
            )
          }
          well={well}
          type="onTurnkey"
          disabled={isLocked}
        />
      )}
      {isChargeModalOpen.offTurnkey && !isDwrLoading && !areChargesLoading && (
        <ChargeRecordModal
          open={isChargeModalOpen.offTurnkey}
          handleClose={() => handleCloseChargeModal("offTurnkey")}
          inventoryItems={inventoryItems}
          serviceItems={serviceItems}
          miscellaneousItems={miscellaneousItems}
          onChargeRecordSelect={(selectedInventoryIds, selectedServiceIds, selectedMiscellaneousIds) =>
            handleChargeRecordSelect(
              selectedInventoryIds,
              selectedServiceIds,
              selectedMiscellaneousIds,
              "offTurnkey"
            )
          }
          type="offTurnkey"
          disabled={isLocked}
          well={well}
        />
      )}
      <ChargeRecord
        inventoryItems={selectedInventoryObjects}
        serviceItems={selectedServiceObjects}
        onTurnkeyChargeItems={onTurnkeyChargeItems}
        offTurnkeyChargeItems={offTurnkeyChargeItems}
        setOnTurnkeyChargeItems={setOnTurnkeyChargeItems}
        setOffTurnkeyChargeItems={setOffTurnkeyChargeItems}
        quantities={quantities}
        updateQuantities={updateQuantities}
        showDuplicateError={showDuplicateChargeError}
        onUpdateChargeRecords={onUpdateChargeRecordsTrigger}
        selectedDwr={selectedDwr}
        disableUpdate={!selectedDwr || isDwrLoading || areChargesLoading || isLocked}
        well={well}
        setZeroAllPrices={setZeroAllPrices}
        chargeRecordIds={chargeRecordIds}
        // currentPricebook={currentPricebook}
        currentInventoryPricebook={currentInventoryPricebook}  // Changed from currentPricebook
        currentServicePricebook={currentServicePricebook}      // Added new prop      
        isLoadingPricebook={isLoadingPricebook}
        disabled={isLocked}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TimeRecord
          ref={timeRecordRef}
          employees={employees}
          organizedEmployees={organizeEmployeesByRig}
          existingETRs={memoizedEmployeeTimeRecords}
          selectedDwr={selectedDwr}
          rigData={rigData}
          disabled={isLocked}
        />
        {selectedDwr && selectedDwr.time_record && selectedDwr.well?.area === "Deleware Basin" && (currentUser.is_manager || currentUser.is_district_manager || currentUser.is_superuser) && (
          <Button
            variant="contained"
            onClick={handleSendToADP}
            disabled={isLocked}
            fullWidth
            sx={{ 
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#095c42'
              }
            }}
          >
            Send Time Records to ADP
          </Button>
        )}
      </Box>
      <h3>Notes</h3>
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        maxLength={2000}
        rows={4}
        cols={50}
        disabled={isLocked}
      />
      {selectedDwr && (
        <button type="button" onClick={handleDWRUpdate} disabled={notesUpdated || isLocked}>
          Update DWR Details
        </button>
      )}
      {!selectedDwr && (
        <button
          type="button"
          className="submit-dwr-button"
          disabled={isSubmitting || isLocked}
          onClick={handleSubmitClick}
        >
          Submit DWR
        </button>
      )}
      {selectedDwr && currentUser.is_superuser && (
        <Button
          variant="contained"
          onClick={handleUploadCSVClick}
          disabled={isLocked || isCreatingSalesOrder}
          startIcon={isCreatingSalesOrder ? <CircularProgress size={20} /> : null}
          sx={{ 
            mb: 2,
            backgroundColor: '#0b6e4f',
            '&:hover': {
              backgroundColor: '#095c42'
            }
          }}
        >
          {isCreatingSalesOrder ? 'Creating Sales Order...' : 'Create Sales Order'}
        </Button>
      )}
    </>
  );

// {/* {selectedDwr && currentUser.is_superuser && (
//     <button
//       type="button"
//       className="create-xlsx-button"
//       onClick={handleCreateXSLXClick}
//     >
//       Create Open Invoice File
//     </button>
//   )} */}




//       {/* Add the button here */}

};

DWRForm.propTypes = {
  selectedDwr: PropTypes.object,
  setSelectedDwr: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subprojectId: PropTypes.number.isRequired,
  getAdjacentDWR: PropTypes.func,
  fetchFullDWRData: PropTypes.func,
  subprojectType: PropTypes.string,
  subprojectNsId: PropTypes.string,
};

DWRForm.defaultProps = {
  getAdjacentDWR: null,
  fetchFullDWRData: null,
  subprojectType: null,
  subprojectNsId: null,
};

export default DWRForm;
