import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, Select, MenuItem, InputLabel, FormControl, TableRow, TableCell } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { convertTo24Hour } from './convertTo24Hour';
import { useAuth } from '../api/AuthContext';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PropTypes from 'prop-types';

// Get App URL
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
  throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

// Rig Short Names
const DELAWARE_BASIN_RIGS = [
  "204", "208", "209", "211", "212",  // 200 series rigs
  "402", "404", "405", "408", "409", "412", "413",  // 400 series rigs
  "16"  // Other rigs
];

const HourSelector = ({ value, onChange, label, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label} Hour</InputLabel>
      <Select value={value} onChange={onChange} disabled={disabled} label={`${label} Hour`}>
        {[...Array(12).keys()].map(n => (
          <MenuItem key={n} value={n + 1}>{n + 1}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MinuteSelector = ({ value, onChange, label, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label} Minute</InputLabel>
      <Select value={value} onChange={onChange} disabled={disabled} label={`${label} Minute`}>
        {['00', '15', '30', '45'].map(minute => (
          <MenuItem key={minute} value={minute}>{minute}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AmPmSelector = ({ value, onChange, label, disabled }) => {
  // console.log(`Rendering AmPmSelector for ${label} with value:`, value);
  // Ensure value is always 'AM' or 'PM'
  const safeValue = value === 'AM' || value === 'PM' ? value : 'AM';
  
  return (
    <FormControl fullWidth>
      <InputLabel>{label} AM/PM</InputLabel>
      <Select 
        value={safeValue}
        onChange={(e) => {
          // console.log(`${label} AM/PM onChange event:`, e.target.value);
          onChange(e);
        }}
        disabled={disabled}
        label={`${label} AM/PM`}
      >
        <MenuItem value="AM">AM</MenuItem>
        <MenuItem value="PM">PM</MenuItem>
      </Select>
    </FormControl>
  );
};

// Add this helper function near the top of the file, before the TimeRecordRow component
const formatEmployeeName = (employee) => {
  if (!employee) return '';
  
  const firstName = employee.first_name || '';
  const lastName = employee.last_name || '';
  const middleName = employee.middle_name || '';

  // If there's a middle name, normalize it to first letter capitalized
  const middleInitial = middleName 
    ? `${middleName.charAt(0).toUpperCase()}. ` 
    : ' ';

  return `${firstName} ${middleInitial}${lastName}`.replace(/\s+/g, ' ').trim();
};

const TimeRecordRow = ({ key, title, employees, onRowChange, data, onDeleteTimeRecord, disabled }) => {
  const { authState } = useAuth();
  const token = authState?.token;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(data.employeeId || '');

  // console.log('TimeRecordRow Props:', {
  //   title,
  //   employeeCount: employees.length,
  //   data: {
  //     employeeId: data.employeeId,
  //     role: data.role,
  //     rigData: data.rigData
  //   }
  // });

  useEffect(() => {
    // console.log('Effect: setting selectedEmployeeId:', {
    //   newEmployeeId: data.employeeId,
    //   currentEmployeeId: selectedEmployeeId
    // });
    setSelectedEmployeeId(data.employeeId || '');
  }, [data.employeeId]);


  // const startConverted = convertTo12Hour(data.start_time);
  // const stopConverted = convertTo12Hour(data.end_time);

  // console.log('startConverted:', startConverted);
  // console.log('stopConverted:', stopConverted);
  
  // console.log('Key: ', key)
  // console.log('DATA: ', data)


  const [timeEntry, setTimeEntry] = useState({
    ...data,
    employeeId: data.employeeId || '',
    id: data.id || '',
    employeeSignature: data.employee ? formatEmployeeName(data.employee) : '',
    startHour: data.startHour || '06',
    startMinute: data.startMinute || '00',
    startMeridian: data.startMeridian || 'AM',
    stopHour: data.stopHour || '6',
    stopMinute: data.stopMinute || '00',
    stopMeridian: data.stopMeridian || 'PM',

    role: data.role || title,
    rig: data.rig || 0,
    travel: data.travel || 0,
    nptJmr: data.nptJmr || 0,
    nptCust: data.nptCust || 0,
    total: calculateInitialTotal(data), // Set initial total
    employees: []
  });

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      if (employees.length === 0) {
        try {
          const response = await axios.get(appUrl + `/jmrapp/employee`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          setTimeEntry(prevState => ({
            ...prevState,
            employees: response.data // Update employees in the timeEntry state
          }));
        } catch (error) {
          console.error('Failed to fetch employees:', error);
        }
      } else {
        setTimeEntry(prevState => ({
          ...prevState,
          employees: employees // Update employees in the timeEntry state with the prop value
        }));
      }
      // console.log('EMPLOYEES :', employees)
    };
  
    fetchEmployees();
  }, [employees, token]);
  

  const filteredEmployees = useMemo(() => {
    const lowerCaseQuery = searchQuery?.toLowerCase() || '';
    
    const validEmployees = (timeEntry.employees || []).filter(e => e && typeof e === 'object');

    // console.log('Filtering employees:', {
    //   title,
    //   rigId: data?.rigData?.id,
    //   rigName: data?.rigData?.short_name,
    //   employeeCount: validEmployees.length,
    //   existingEmployeeId: data.employeeId // Log the existing employee ID
    // });

    if (!title) {
      return [];
    }

    let filtered;
    
    // Check if rig is in Delaware Basin using short_name
    const isDelawareBasinRig = data?.rigData?.short_name && 
      DELAWARE_BASIN_RIGS.includes(data.rigData.short_name);
    
    if (isDelawareBasinRig) {
      // Modified Delaware Basin filtering logic
      if (data.employeeId) {
        // For existing records, include the assigned employee regardless of ADP status
        filtered = validEmployees.filter(e => 
          e.id === data.employeeId || // Include the existing employee
          (e.endpoint_id === null && Boolean(e.adp_associate_oid)) // And include valid new employees
        );
      } else {
        // For new selections, only show employees with ADP IDs
        filtered = validEmployees.filter(e => 
          e.endpoint_id === null && 
          Boolean(e.adp_associate_oid)
        );
      }
    } else {
      // Existing non-Delaware Basin logic...
      if (['Rig Operator', 'Derrick Hand', 'Floor Hand'].includes(title)) {
        filtered = validEmployees.filter((e) =>
          e.job_title && ['rig_operator', 'derrick_hand', 'floor_hand'].includes(e.job_title)
        );
      } else if (['Heavy Equipment Operator', 'Reclamation Supervisor'].includes(title)) {
        filtered = validEmployees.filter((e) =>
          e.job_title && ['heavy_equipment_operator', 'reclamation_supervisor'].includes(e.job_title)
        );
      } else {
        const normalizedTitle = title.toLowerCase().replace(/\s+/g, '_');
        filtered = validEmployees.filter((e) => {
          const employeeJobTitle = e.job_title || '';
          const matches = employeeJobTitle === normalizedTitle || employeeJobTitle === data.role;
          if (matches) {
            // console.log('Matched employee:', {
            //   employeeId: e.id,
            //   employeeJobTitle,
            //   normalizedTitle,
            //   dataRole: data.role
            // });
          }
          return matches;
        });
      }
    }

    // Apply search query filter if one exists
    if (lowerCaseQuery) {
      filtered = filtered.filter((e) => {
        const fullName = formatEmployeeName(e).toLowerCase();
        return fullName.includes(lowerCaseQuery);
      });
    }

    // Sort alphabetically by first name, then last name
    return filtered.sort((a, b) => {
      const nameA = formatEmployeeName(a).toLowerCase();
      const nameB = formatEmployeeName(b).toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }, [timeEntry.employees, title, searchQuery, data?.rigData, data.employeeId, data.role]);

  // Calculate initial total
  function calculateInitialTotal(data) {
    return parseFloat((data.rig || 0) + (data.travel || 0) + (data.nptJmr || 0) + (data.nptCust || 0)).toFixed(2);
  }
  

  const [errorState, setErrorState] = useState('');

  // Calculate total hours from start to stop time
  const calculateTotalTimeAsDecimal = useCallback(() => {
    const startTime = convertTo24Hour(timeEntry.startHour, timeEntry.startMinute, timeEntry.startMeridian);
    const stopTime = convertTo24Hour(timeEntry.stopHour, timeEntry.stopMinute, timeEntry.stopMeridian);
    const startDecimal = parseInt(startTime.split(':')[0]) + parseInt(startTime.split(':')[1]) / 60;
    const stopDecimal = parseInt(stopTime.split(':')[0]) + parseInt(stopTime.split(':')[1]) / 60;
    let totalHours = stopDecimal - startDecimal;
    if (totalHours < 0) totalHours += 24; // correct negative total hours for overnight work
    return totalHours;
  }, [timeEntry.startHour, timeEntry.startMinute, timeEntry.startMeridian, timeEntry.stopHour, timeEntry.stopMinute, timeEntry.stopMeridian]);



  const handleTimeChange = (field, value) => {
    // console.log(`handleTimeChange called with field: ${field}, value: ${value}`);
  
    // Special handling for AM/PM fields
    if (field === 'startMeridian' || field === 'stopMeridian') {
      // console.log(`${field} changed to:`, value);
      if (value !== 'AM' && value !== 'PM') {
        console.error(`Invalid meridian value for ${field}:`, value);
        return; // Exit without updating state
      }
    } else {
      // For non-AM/PM fields, check for NaN and empty values
      if (isNaN(value) || value === '') {
        console.error(`Attempted to set ${field} to invalid value:`, value);
        return; // Exit the function without updating state
      }
    }
  
    let parsedValue;
    if (field === 'startMeridian' || field === 'stopMeridian') {
      parsedValue = value; // Keep AM/PM as strings
    } else if (field === 'startMinute' || field === 'stopMinute') {
      parsedValue = value; // Keep minutes as strings to preserve leading zeros
    } else {
      parsedValue = parseFloat(value);
    }
  
    const updatedData = {
      ...timeEntry,
      [field]: parsedValue,
      rig: timeEntry.rig,
      travel: timeEntry.travel,
      nptJmr: timeEntry.nptJmr,
      nptCust: timeEntry.nptCust,
    };
  
    if (field === 'rig' || field === 'travel' || field === 'nptJmr' || field === 'nptCust') {
      updatedData[field] = parsedValue;
    }
  
    const updatedTimeEntry = {
      ...timeEntry,
      ...updatedData,
      data: {
        ...timeEntry.data,
        ...updatedData,
        id: timeEntry.data && timeEntry.data.id ? timeEntry.data.id : undefined,
      },
    };
  
    // console.log('Updated Time Entry before setState:', JSON.stringify(updatedTimeEntry, null, 2));
    setTimeEntry(updatedTimeEntry);
    
    // Use a setTimeout to log the state after it's been updated
    setTimeout(() => {
      // console.log('Time Entry after setState:', JSON.stringify(timeEntry, null, 2));
    }, 0);
  
    onRowChange(updatedTimeEntry);
  };


  const { startHour, startMinute, startAmPm, stopHour, stopMinute, stopAmPm } = timeEntry;


  useEffect(() => {
    const workHours = calculateTotalTimeAsDecimal();
    const total = (workHours + timeEntry.rig + timeEntry.travel + timeEntry.nptJmr + timeEntry.nptCust).toFixed(2);
    if (total !== timeEntry.total) {
      
      setTimeEntry(prev => ({ ...prev, total }));
      onRowChange({ ...timeEntry, total });
    }
  }, [timeEntry.startHour, timeEntry.startMinute, timeEntry.startMeridian, timeEntry.stopHour, timeEntry.stopMinute, timeEntry.stopMeridian, timeEntry.rig, timeEntry.travel, timeEntry.nptJmr, timeEntry.nptCust, onRowChange]);



  // console.log ('DATA OBJECT: ', data)
  useEffect(() => {
    if (data.employeeId) {
      const selectedEmployee = employees.find((e) => e.id === data.employeeId);
      if (selectedEmployee) {
        const isLaborHand = selectedEmployee.job_title === 'labor_hand';
        const isSupervisor = selectedEmployee.job_title === 'rig_supervisor';
        const updatedSignature = isSupervisor ? `${selectedEmployee.first_name} ${selectedEmployee.last_name}` : '';
        setTimeEntry((prev) => ({
          ...prev,
          employeeSignature: updatedSignature,
          role: selectedEmployee.job_title,
          employees: employees, // Update the employees array in the timeEntry state
        }));
      } else {
        setTimeEntry((prev) => ({
          ...prev,
          employeeSignature: '',
          role: data.role || title,
          employees: employees, // Update the employees array in the timeEntry state
        }));
      }
    } else {
      setTimeEntry((prev) => ({
        ...prev,
        employeeSignature: '',
        role: data.role || title,
        employees: employees, // Update the employees array in the timeEntry state
      }));
    }
  }, [data.employeeId, data.role, employees, title]);


const handleSelectChange = (event) => {
  const { value } = event.target;
  const selectedEmployee = employees.find((e) => e.id === value);

  const updatedData = {
    ...timeEntry,
    employeeId: value,
    employeeSignature: selectedEmployee ? formatEmployeeName(selectedEmployee) : '',
    role: title,
  };

  const updatedTimeEntry = {
    ...timeEntry,
    ...updatedData,
    data: {
      ...timeEntry.data,
      ...updatedData,
      id: timeEntry.data && timeEntry.data.id ? timeEntry.data.id : undefined,
    },
  };

  setTimeEntry(updatedTimeEntry);
  onRowChange(updatedTimeEntry);
};


useEffect(() => {
  const totalDecimal = calculateTotalTimeAsDecimal();
  if (timeEntry.total !== parseFloat(totalDecimal)) {
    setTimeEntry((prev) => ({
      ...prev,
      total: parseFloat(totalDecimal),
    }));
  }
}, [
  timeEntry.startHour,
  timeEntry.startMinute,
  timeEntry.startMeridian,
  timeEntry.stopHour,
  timeEntry.stopMinute,
  timeEntry.stopMeridian,
  calculateTotalTimeAsDecimal,
]);

  // console.log('DATA VALUE: ', data)
  const handleDeleteTimeRecord = async (timeEntry) => {
    // console.log('TimeRecordRow - Delete initiated for:', {
    //   timeEntry,
    //   data,
    //   title
    // });

    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this time record?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            // Create a deletion object with the necessary fields
            const deletionData = {
              ...timeEntry,
              id: data.etrId || data.id, // Use etrId for existing records
              etrId: data.etrId || (data.data && data.data.id), // Get etrId from either location
              role: data.role || title // Ensure we pass the role
            };
            // console.log('TimeRecordRow - Deletion confirmed, sending data:', deletionData);
            onDeleteTimeRecord(deletionData);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // console.log('TimeRecordRow - Deletion cancelled');
          },
        },
      ],
    });
  };




  return (
    <TableRow>
      <TableCell style={{ width: '10%', minWidth: '25px' }}>{title}</TableCell>
      <TableCell style={{ width: '5%', minWidth: '25px' }}>
        <FormControl fullWidth>
          <TextField
            label="Search Employee"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            variant="outlined"
            style={{ marginBottom: '8px' }}
            disabled={disabled}
          />
          <InputLabel id={`employee-select-label-${timeEntry.employeeId}`}>
            Employee
          </InputLabel>
          <Select
            labelId={`employee-select-label-${timeEntry.employeeId}`}
            id={`employee-select-${timeEntry.employeeId}`}
            value={timeEntry.employeeId}
            onChange={handleSelectChange}
            disabled={disabled}
          >
            {filteredEmployees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {formatEmployeeName(employee)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell style={{ width: '10%', minWidth: '25px' }} disabled={disabled}>
        <HourSelector label="Start" value={timeEntry.startHour} disabled={disabled} onChange={(e) => handleTimeChange('startHour', e.target.value)}/>
        <MinuteSelector label="Start" value={timeEntry.startMinute} disabled={disabled} onChange={(e) => handleTimeChange('startMinute', e.target.value)}/>             
        <AmPmSelector label="Start" value={timeEntry.startMeridian} disabled={disabled} onChange={(e) => handleTimeChange('startMeridian', e.target.value)}/>
      </TableCell>
      <TableCell style={{ width: '10%', minWidth: '25px' }}>
        <HourSelector label="Stop" value={timeEntry.stopHour} disabled={disabled} onChange={(e) => handleTimeChange('stopHour', e.target.value)}/>
        <MinuteSelector label="Stop" value={timeEntry.stopMinute} onChange={(e) => handleTimeChange('stopMinute', e.target.value)} disabled={disabled}/>
        <AmPmSelector label="Stop" value={timeEntry.stopMeridian} onChange={(e) => handleTimeChange('stopMeridian', e.target.value)} disabled={disabled}/>
      </TableCell>
      <TableCell style={{ width: '10%', minWidth: '50px' }}>
        <Select
          label="Rig"
          value={timeEntry.rig}
          onChange={(e) => handleTimeChange('rig', parseFloat(e.target.value))}
          disabled={disabled}
          fullWidth
        >
          {Array.from({ length: 49 }, (_, i) => (
            <MenuItem key={i} value={i * 0.5}>
              {i * 0.5}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell style={{ width: '10%', minWidth: '50px' }}>
        <Select
          label="Travel"
          value={timeEntry.travel}
          onChange={(e) => handleTimeChange('travel', parseFloat(e.target.value))}
          disabled={disabled}
          fullWidth
        >
          {Array.from({ length: 49 }, (_, i) => (
            <MenuItem key={i} value={i * 0.5}>
              {i * 0.5}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell style={{ width: '10%', minWidth: '50px' }}>
        <Select
          label="NPT JMR"
          value={timeEntry.nptJmr}
          onChange={(e) => handleTimeChange('nptJmr', parseFloat(e.target.value))}
          disabled={disabled}
          fullWidth
        >
          {Array.from({ length: 49 }, (_, i) => (
            <MenuItem key={i} value={i * 0.5}>
              {i * 0.5}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell style={{ width: '10%', minWidth: '50px' }}>
        <Select
          label="NPT Cust"
          value={timeEntry.nptCust}
          onChange={(e) => handleTimeChange('nptCust', parseFloat(e.target.value))}
          disabled={disabled}
          fullWidth
        >
          {Array.from({ length: 49 }, (_, i) => (
            <MenuItem key={i} value={i * 0.5}>
              {i * 0.5}
            </MenuItem>
          ))}
        </Select>
      </TableCell>  
      <TableCell style={{ width: '4%', minWidth: '20px' }}>
        {errorState && (
          <div style={{ color: 'red' }}>{errorState}</div>
        )}
        {calculateTotalTimeAsDecimal()}
      </TableCell>
      <TableCell style={{ width: '4%', minWidth: '20px' }}>
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteTimeRecord(timeEntry)}
          disabled={disabled}
          size="small"
          style={{ color: disabled ? '#D3D3D3' : 'red' }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>


    </TableRow>
  );
};

export default TimeRecordRow;
